import React, {Component} from "react";
import * as ServicePopNotice from "../service/ServicePopNotice";
import { formatStringDate } from "../utils/StringUtils";
var year = (new Date()).getYear() - 100
class PopNoticeAddForm extends Component {
    constructor(props) {
        super(props);
        var languages = new Map()

        languages.set('ko', '한국어')
        languages.set('en', 'English')
        languages.set('tw', '대만어')
        languages.set('jp', '일번어')
        languages.set('cn', '중국어')
        
        var yearList = [2023, 2024, 2025, 2026, 2027];
        var monthlist = [1,2,3,4,5,6,7,8,9,10,11,12];
        var daylist = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];
        var today = new Date();
        // var dateString = this.props.data?.expireDate;
        // var dateArray = dateString.split("-");
        // this.props.data?.expireDate ? dateArray[0] :

        this.state = {
            yy : today.getFullYear().toString(),
            mm :  today.getMonth()+1,
            dd : today.getDate(),
            yearList: yearList,
            daylist: daylist,
            monthlist: monthlist,
            language: this.props.data?.language ? this.props.data?.language : 'ko',
            image: this.props.data?.image ? this.props.data?.image: '',
            expireDate: this.props.data?.expireDate ? formatStringDate(this.props.data.expireDate) : '',
            id:  this.props.data?.id ? this.props.data?.id : '',
            languages: languages

        }
        console.log("Data", this.props.data);

        this.innerConstructor();

        this.handlerFormAddNotice = this.handlerFormAddNotice.bind(this);
        this.handlerFormUpdate = this.handlerFormUpdate.bind(this);
        this.handlerYear = this.handlerYear.bind(this);
        this.handlerMonth = this.handlerMonth.bind(this);
        this.handlerDay = this.handlerDay.bind(this);
        this.handlerLanguage = this.handlerLanguage.bind(this);
    }

    innerConstructor() {
        if(this.state.expireDate !== ""){
            console.log("expireDate est: "+ this.state.expireDate )
           var dateArray = this.state.expireDate.split("-");
           console.log("expireDateArray  est: "+ this.state.expireDate )
           var y = dateArray[0];
           var m = dateArray[1];
           var d = dateArray[2];
           this.setState({yy: y})
           this.setState({mm: m})
           this.setState({dd: d})
         
        }
    }
    handlerLanguage = (e) => {
        console.log("lang ", e.target.value)
        this.setState({language: e.target.value});
    }

    handlerImage = (e) => {
        this.setState({image: e.target.value});
    }

    handlerYear = (e) => {
        this.setState({yy: e.target.value});
    }

    handlerMonth = (e) => {
        this.setState({mm: e.target.value});
    }

    handlerDay = (e) => {
        this.setState({dd: e.target.value});
    }

    handlerFormAddNotice = (e) => {
        console.log("in submit create handler")
        e.preventDefault();
        if(this.state.image === "" || this.state.language === "") {
            window.alert("please enter language andd image link");
            return;
        }
        this.state.expireDate =this.state.yy.toString() + "-" + this.state.mm.toString() + "-" + this.state.dd.toString();
        var formInfo = {
            language: this.state.language,
            image: this.state.image,
            expireDate: this.state.expireDate
        }

        if(this.state.id === "") {
            console.log("after updateNoticeId handler", formInfo)
            if(window.confirm("are you sure you want to add new notice?")) {
            ServicePopNotice.svcAddPopNotice(formInfo)
            .then(response => {
                console.log("notice added !!!");
                // window.location="/notice";
            })
            .catch(error => {
                window.alert("database error : "+error);
                console.log("database error "+ error)
            });
        }}


     
    }

    handlerFormUpdate = (e) => {
        // console.log("in update handler"+ "" + this.state.expireDate +" " + this.state.id +" " + this.state.language +" " + this.state.image )
        e.preventDefault();
        this.state.expireDate =this.state.yy.toString() + "-" + this.state.mm.toString() + "-" + this.state.dd.toString();
        var formInfo = {
            id: this.state.id,
            language: this.state.language,
            image: this.state.image,
            expireDate: this.state.expireDate
        }
        // console.log("in update aftern formInfo ", formInfo )

        if(this.state.image === "" || this.state.language === "" || this.state.expireDate ==="" || this.state.id === "") {
            window.alert("please enter language andd image link");
            return;
        }
      
        if(this.state.id !== "") {
            console.log("after updateNoticeId handler", formInfo)
            if(window.confirm("are you sure you want to update the notice?")) {
            ServicePopNotice.svcUpdatePopNotice(formInfo)
            .then(response => {
                console.log("notice updated !!!");
            })
            .catch(error => {
                // window.alert("database error : "+error);
                console.log("database error "+ error)
            });
        }}

    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="col-md-8 order-md-1">
                <h4>Notice Name and Image Info</h4>
                <br />
                <label htmlFor="pname">Notice Language</label>
                <div className="col-md-2">
                            <select className="custom-select w-100" id="mm" onChange={this.handlerLanguage} defaultValue={this.state.language}>
                            {
                                Array.from(this.state.languages.keys()).map((val,i)=> {
                                    return (
                                        <option value={val} key={i}>{this.state.languages.get(val)}</option>
                                    );
                                })
                            }
                            </select>
                        </div>
                    <br />
                    <label htmlFor="pclass">Notice Image</label>
                    <div className="row">
                    <input type="text" className="form-control" id="image" value={this.state.image} onChange={this.handlerImage} placeholder="Enter the notice image link Please"></input>
                    </div>
                    <br />
                    <br />
                <h4>Notice Expiration Info</h4>
                <form onSubmit={this.handlerFormAddNotice}>
                    <br />
                    <label htmlFor="email">Expiration Date</label>
                    <div className="row">
                    <div className="col-md-2">
                            <select className="custom-select w-100" id="yy" onChange={this.handlerYear} defaultValue={this.state.yy}>
                            {
                                this.state.yearList.map((val,i)=> {
                                    return (
                                        <option value={val} key={i}>{val}</option>
                                    );
                                })
                            }
                            </select>
                        </div> 년
                        <div className="col-md-2">
                            <select className="custom-select w-100" id="mm" onChange={this.handlerMonth} defaultValue={this.state.mm}>
                            {
                                this.state.monthlist.map((val,i)=> {
                                    return (
                                        <option value={val} key={i}>{val < 10 ? "0" + val : val}</option>
                                    );
                                })
                            }
                            </select>
                        </div> 월
                        <div className="col-md-2">
                            <select className="custom-select w-100" id="dd" onChange={this.handlerDay} defaultValue={this.state.dd}>
                            {
                                this.state.daylist.map((val,i)=> {
                                    return (
                                        <option value={val} key={i}>{val < 10 ? "0" + val : val}</option>
                                    );
                                })
                            }
                            </select>
                        </div> 일
                       
                    </div>
                    <br />
                    
                    <br />
                    <button
                        className="btn btn-primary btn-lg btn-block" 
                        type="submit" 
                        onClick={this.props.viewMode === 'detail' ? this.handlerFormUpdate : this.handlerFormAddNotice}
                    >
                        {this.props.viewMode == 'detail' ? "Update" : "Create"}</button>
                </form>
            </div>
        );
    }
}
export default PopNoticeAddForm;