/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from "react";
import * as ServiceEvents from "../service/ServiceEvents";

const checktxts = ["Check", "Uncheck"]
const rewardtxts = ["Not Rewarded", "Rewarded"]

class EventsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventslist : null,
            contentCount : 0,
            contentPerPage : 10,
            pageNow : 1,
            lastPage : 1,
        }

        this.handlerOnBtnDetail = this.handlerOnBtnDetail.bind(this);
        this.handlerOnBtnCheck = this.handlerOnBtnCheck.bind(this);
        this.handlerOnPage = this.handlerOnPage.bind(this);
        this.innerConstructor();
    }


    handlerOnBtnDetail(id) {
        console.log("UID: "+id);
        this.props.callBackForDetail(id);
    }

    handlerOnBtnCheck(id, check) {

        if(check == 0) check = 1;
        else check = 0;

        ServiceEvents.svcCheckEvent(id, check)
        .then(response => {
            console.log(response);
            this.innerConstructor();
        })
        .catch(error => {
            console.log("eventslist.js : handlerOnBtnDel : fetch list error "+error);
        });
    }


    handlerOnPage(page) {
        if(page < 1 ) page = 1
        else if (page > this.state.lastPage) page = this.state.lastPage
        
        this.setState({pageNow:page});
        ServiceEvents.svcEventsList(page,this.state.contentPerPage, this.props.filter)
        .then(response => {
            console.log(response);
            this.setState({eventslist: response});
        })
        .catch(error => {
            console.log("eventslist.js : innerConstructor : fetch list error "+error);
        });
}

    innerConstructor() {

        ServiceEvents.svcEventsCount(this.props.filter)
        .then(response => {
            this.setState({contentCount:response.count});
            this.setState({lastPage:Math.ceil(response.count/this.state.contentPerPage)});
            // first content count and fetch list
            ServiceEvents.svcEventsList(this.state.pageNow,this.state.contentPerPage, this.props.filter)
            .then(response => {
                this.setState({eventslist: response});
            })
            .catch(error => {
                console.log("eventslist.js : innerConstructor : fetch list error "+error);
            });
        })
        .catch(error => {
            console.log("database error "+error);
        });
    }

    render() {
        console.log("Filter: "+this.props.filter)
        var pageNaviNum = [];
        for(var i=this.state.pageNow;i<this.state.pageNow+3;i++) {
            pageNaviNum.push(i);
          }
        if(!this.state.eventslist){
          return <div/>
        };

        return (
          <div className="container-fluid">
            <div className="row table-responsive">
                <table className="table table-striped table-sm">
                    <thead>
                        <tr>
                            <th>NO</th>
                            <th>아이디</th>
                            <th>전화번호</th>
                            <th>신청일</th>
                            <th>마지막 출석일</th>
                            <th>보상 여부</th>
                            <th>보기/리워드 체크</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.eventslist.map((val,i) => {

                            return (
                                <tr key={i}>
                                    <td>{i}</td>
                                    <td>{val.userid}</td>
                                    <td>{val.phone}</td>
                                    <td>{val.subscription_date}</td>
                                    <td>{val.attendance_end}</td>
                                    {val.rewarded === 1 &&
                                      <td className="text-success" >보상됨</td>
                                    }
                                    {val.rewarded === 0 &&
                                        <td className="text-warning">보상 안됨...</td>
                                    }
                                    <td>
                                        <button className="btn btn-outline-secondary btn-sm" onClick={() => {this.handlerOnBtnDetail(val.uid)}}>보기</button>&nbsp;
                                        <button className="btn btn-outline-primary btn-sm" onClick={() => {this.handlerOnBtnCheck(val.id, val.rewarded)}}>{checktxts[val.rewarded]}</button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

            </div>
            <div className="row overflow-auto">
            { this.state.lastPage > 1 &&
            <nav aria-label="Page navigation example">
                <ul className="pagination">
            <li className="page-item"><a className="page-link" onClick={() => {this.handlerOnPage(this.state.pageNow - 3)}}>{"◀"}</a></li>
                { pageNaviNum.map((val,i) => {
                    return (
                      <>
                        <li key={i} className="page-item"><a className={`page-link ${this.state.pageNow === val && 'bg-primary text-white'}`} onClick={() => {this.handlerOnPage(val)}}>{val}</a></li>
                      </>
                    )
                })}
                <li className="page-item"><a className="page-link" onClick={() => {this.handlerOnPage(this.state.pageNow + 3)}}>{"▶"}</a></li>
                </ul>
            </nav>
            }
            </div>
            </div>
        );
    }
}

export default EventsList;
