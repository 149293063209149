/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from "react";
import * as ServiceFAQs from "../service/ServiceFAQs";
import * as ServiceUser from "../service/ServiceUser";
import * as TimeUtil from "../utils/TimeUtil";
import * as StringUtils from "../utils/StringUtils";

var today = new Date();

class FAQForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: this.props.faqData ? this.props.faqData.type : 0,
            inquiry: this.props.faqData ? this.props.faqData.inquiry : "",
            reply: this.props.faqData ? this.props.faqData.reply : "",
            language: this.props.faqData ? this.props.faqData.language : "ko"

        }
        this.handlerLangChange = this.handlerLangChange.bind(this);
        this.handlerTypeChange = this.handlerTypeChange.bind(this);
        this.handlerOnInquiryChange = this.handlerOnInquiryChange.bind(this);
        this.handlerOnReplyChange = this.handlerOnReplyChange.bind(this);
        this.handlerFormSubmit = this.handlerFormSubmit.bind(this);

        this.innerConstructor();
    }

    innerConstructor() {
        console.log("FAQForm.js : innerContstructor : end");
    }

    handlerOnReplyChange = (e) => {
        this.setState({reply: e.target.value});
    }

    handlerOnInquiryChange = (e) => {
        this.setState({inquiry: e.target.value});
    }

    handlerTypeChange = (e) => {
        this.setState({type: parseInt(e.target.value)});
    }
    handlerLangChange = (e) => {
        this.setState({language: e.target.value});
    }

    handlerFormSubmit = (e) => {
        e.preventDefault();
        var data = {
            id: this.props.faqData ? this.props.faqData.id : 0,
            type: this.state.type,
            inquiry: this.state.inquiry,
            reply: this.state.reply,
            language : this.state.language,
        }
        console.log("faq data", data)
        if(!this.props.add){
            ServiceFAQs.svcUpdateFaq(data)
              .then(response => {
               window.location="/admin/faqs";
               window.alert("Faq updated");
            })
            .catch(error => {
               window.alert("Couldn't update : "+error);
            });
        }
        else{
           ServiceFAQs.svcAddFaq(data)
             .then(response => {
              window.location="/admin/faqs";
              window.alert("FAQ added");
           })
           .catch(error => {
              window.alert("Couldn't update : "+error);
           });
         }
    }

    componentDidMount() {

    }

    render() {
      
        return (
            <div className="col-md-8 order-md-1">
            <form onSubmit={this.handlerFormSubmit}>
                <label>유형</label>
                <div className="row pl-4">
                    <div className="custom-control custom-radio col-md-3">
                        <input id="0" value="0" type="radio" className="custom-control-input" required onChange={this.handlerTypeChange} checked={this.state.type === 0}></input>
                        <label className="custom-control-label" htmlFor="0">디바이스</label>
                    </div>
                    <div className="custom-control custom-radio col-md-3">
                        <input id="1" value="1" type="radio" className="custom-control-input" required onChange={this.handlerTypeChange} checked={this.state.type === 1}></input>
                        <label className="custom-control-label" htmlFor="1">APP</label>
                    </div>
                    <div className="custom-control custom-radio col-md-3">
                        <input id="2" value="2" type="radio" className="custom-control-input" required onChange={this.handlerTypeChange} checked={this.state.type === 2}></input>
                        <label className="custom-control-label" htmlFor="2">눈 운동</label>
                    </div>
                    <div className="custom-control custom-radio col-md-3">
                        <input id="3" value="3" type="radio" className="custom-control-input" required onChange={this.handlerTypeChange} checked={this.state.type === 3}></input>
                        <label className="custom-control-label" htmlFor="3">서비스/기타</label>
                    </div>
                </div>
                <br/>
                <label>언어</label>
                <div className="row pl-4">
                    <div className="custom-control custom-radio col-md-3">
                        <input id="ko" value="ko" type="radio" className="custom-control-input" required onChange={this.handlerLangChange} checked={this.state.language === "ko"}></input>
                        <label className="custom-control-label" htmlFor="ko">한국어</label>
                    </div>
                    <div className="custom-control custom-radio col-md-3">
                        <input id="en" value="en" type="radio" className="custom-control-input" required onChange={this.handlerLangChange} checked={this.state.language === "en"}></input>
                        <label className="custom-control-label" htmlFor="en">영어</label>
                    </div>
                    <div className="custom-control custom-radio col-md-3">
                        <input id="jp" value="jp" type="radio" className="custom-control-input" required onChange={this.handlerLangChange} checked={this.state.language === "jp"}></input>
                        <label className="custom-control-label" htmlFor="jp">일본어</label>
                    </div>
                    <div className="custom-control custom-radio col-md-3">
                        <input id="cn" value="cn" type="radio" className="custom-control-input" required onChange={this.handlerLangChange} checked={this.state.language === "cn"}></input>
                        <label className="custom-control-label" htmlFor="cn">중국어</label>
                    </div>
                    <div className="custom-control custom-radio col-md-3">
                        <input id="tw" value="tw" type="radio" className="custom-control-input" required onChange={this.handlerLangChange} checked={this.state.language === "tw"}></input>
                        <label className="custom-control-label" htmlFor="tw">대만어</label>
                    </div>
                </div>
                <br/>
                <label>질문</label>
                <div className="input-group">
                    <textarea style={{height:"200px"}} className="form-control" aria-label="With textarea" value={this.state.inquiry} onChange={this.handlerOnInquiryChange}></textarea>
                    <div className="input-group-prepend">
                    </div>
                </div>
                <label>대답</label>
                <div className="input-group">
                    <textarea style={{height:"200px"}} className="form-control" aria-label="With textarea" value={this.state.reply} onChange={this.handlerOnReplyChange}></textarea>
                    <div className="input-group-prepend">
                    </div>
                </div>
                <br/>
                <button className="btn btn-outline-primary btn-lg btn-block" type="submit">완료</button>
            </form>


            </div>
        );
    }
}

export default FAQForm;
