import React, {Component} from "react";
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import Footer from "../components/Footer";
import SerialList from "../components/SerialList";
import SerialAddForm from "../components/SerialAddForm";
import * as ServiceLogin from "../service/ServiceLogin";

class SerialManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            viewMode : "list",
            SirialNumber : "",
            filter:"",
            filtered:0
        }

        this.handlerAddSerial = this.handlerAddSerial.bind(this);
        this.handlerListSerial = this.handlerListSerial.bind(this);
        this.handlerUpdateSerial = this.handlerUpdateSerial.bind(this);
        this.handlerDetailSerial = this.handlerDetailSerial.bind(this);
        this.handlerOnChange = this.handlerOnChange.bind(this);
        this.handlerOnSearch = this.handlerOnSearch.bind(this);

        this.innerConstructor();
    }

    preRender() {
        console.log("DashBoard.js : preRender : rendering");
    }

    innerConstructor() {
        ServiceLogin.svcLoginCheck();
        console.log("UserManagement.js : innerConstructor : end");
    }

    handlerAddSerial() {
      //new
        this.setState({viewMode:"add",SirialNumber:""});
        console.log("handler add user");
    }

    handlerListSerial() {
      //new
        this.setState({viewMode:"list"});
    }

    handlerUpdateSerial(id) {
      //new
        this.setState({viewMode:"add",SirialNumber:id});
    }

    handlerDetailSerial(id) {
        this.setState({viewMode:"detail",SirialNumber:id});
    }
    handlerOnChange(event){
      this.setState({filter:event.target.value});
    }
    handlerOnSearch(event){
      event.preventDefault();
      this.setState({filtered: this.state.filtered+1})
    }

    render() {
        this.preRender();
        return (
            <div>
                <NavBar />
                <div className="container-fluid">
                    <div className="row">
                        <SideBar />
                        <main className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
                            { this.state.viewMode === "list" &&
                                <div>
                                    <h2>시리얼 관리</h2>
                                    <form onSubmit={this.handlerOnSearch}>
                                        <div className="input-group mb-3">
                                          <input type="text" className="form-control" placeholder="시리얼번호 검색 입력..." value={this.state.filter} onChange={this.handlerOnChange} aria-label="Filter" aria-describedby="basic-addon2"/>
                                             <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" type="submit">검색</button>
                                             </div>
                                        </div>
                                    </form>
                                    <div className="col-md-12 text-right mb-3">
                                        <button className="btn btn-outline-primary btn-sm" onClick={this.handlerAddSerial}>Create Serial</button><br />
                                    </div>
                                    <div key={this.state.filtered}>
                                        <SerialList callBackForUpdate={this.handlerUpdateSerial} callBackForDetail={this.handlerDetailSerial} filter={this.state.filter}/>
                                    </div>
                                </div>
                            }
                            {
                                this.state.viewMode === "add" &&
                                <div>
                                    <h2>Create New Serials</h2>
                                    <div className="col-md-12 text-right mb-3">
                                        <button className="btn btn-outline-primary btn-sm" onClick={this.handlerListSerial}>시리얼번호 내역</button><br />
                                    </div>
                                    <div>
                                        <SerialAddForm SirialNumber={this.state.SirialNumber}/>
                                    </div>
                                </div>
                            }
                        </main>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default SerialManagement;
