/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from "react";
import * as ServiceNotice from "../service/ServiceNotice";
const nl2br = require('react-nl2br');

class NoticeView extends Component {
    constructor(props) {
        super(props);
        this.innerConstructor();
        this.state = {
            title: "",
            content : ""
        }
    }

    innerConstructor() {
        console.log("UserView.js : innerContstructor : end");
    }

    componentDidMount() {
        if(this.props.NoticeId !== "") {
            ServiceNotice.svcGetNotice(this.props.NoticeId)
            .then(response => {
                this.setState({title:response.title,content:response.content});
            })
            .catch(error => {
                window.alert("database error : "+error);
            })
        }
    }

    render() {
        return (
            <div className="col-md-8 order-md-1">
                <h4>Notice</h4>
                <br />
                {this.state.title}
                <hr />
                {nl2br(this.state.content)}
            </div>
        );
    }
}

export default NoticeView;