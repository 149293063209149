import axios from "axios";
import backServer from "./ServerInfo";

export function svcPopNoticeCount() {
    return new Promise(function(resolve,reject){
        axios.post(backServer + "/getnoticecount",
        {
        //   filter:filter,                                                              
          DBId:1
        },
        {headers:{"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            console.log("then NoticeCount SVCPopNotice");
            resolve(response.data);
        })
        .catch(error => {
            console.log(error);
            console.log("Catch NoticeCount SVCPopNotice");
        })
    })
}

export function svcPopNoticeList(start,limit,filter) {
  console.log("Filter before send: "+filter)
    return new Promise (function(resolve,reject){
        axios.post(backServer + "/listpopnotice",
        {
            start:(start-1)*limit,
            limit:limit,
            filter:filter,
            DBId:1
        },
		{headers:{"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            console.log(response.data);
            if(response.data.status === "fail") {
                console.log("comm not ok");
                reject();
            } else {
                console.log("comm ok");
                console.log(response);
                resolve(response.data);
            }
        })
        .catch(error => {
            console.log("svcNoticeList : error axios");
            //window.location="/admin/login";
            reject();
        });
    });
}

export function svcAddPopNotice(value) {
    return new Promise (function(resolve,reject){
        axios.post(backServer + "/addpopnotice",
        {
            image:value.image,
            language:value.language,
            expireDate: value.expireDate,
            DBId:1
        },
        {headers:{"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            if(response.data.status === "fail") {
                reject();
            } else {
                resolve();
                console.log("infos service", value)
            }
        })
        .catch(error => {
            reject();
        });
    });
}

export function svcUpdatePopNotice(value) {
    console.log("update data: ", value)
    return new Promise (function(resolve,reject){
        axios.post(backServer + "/updatepopnotice",
        {
            image:value.image,
            language:value.language,
            expireDate: value.expireDate,
            id: value.id,
            DBId:1
        },
        {headers:{"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            if(response.data.status === "fail") {
                reject();
            } else {
                resolve();
            }
        })
        .catch(error => {
            reject();
        });
    });
}

export function svcDelPopNotice(value) {
    let data = JSON.stringify({
        id:value,
        DBId:1
    });
    return new Promise (function(resolve,reject){
        axios.post(backServer + "/delpopnotice",
        data,
		{headers:{'Content-Type': 'application/json',"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            console.log(response.data);
            if(response.data.status === "fail") {
                reject();
            } else {
                resolve();
            }
        })
        .catch(error => {
            reject();
        });
    });
}

export function svcAddPopNoticeDetail(value) {
    return new Promise (function(resolve,reject){
        axios.post(backServer + "/addnoticedetail",
        value,
        {headers:{'Content-Type': 'application/json',"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            if(response.data.status === "fail") {
                reject();
            } else {
                resolve();
            }
        })
        .catch(error => {
            reject();
        });
    });
}


export function svcGetPopNoticeDetail(value) {
    let data = JSON.stringify({
        id:value,
        DBId:1
    });
    return new Promise (function(resolve,reject){
        axios.post(backServer + "/getpopnoticedetail",
        data,
		{headers:{'Content-Type': 'application/json',"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            console.log(response);
            if(response.data.id !== 0) {
                resolve(response.data);
            } else {
                reject(response.data);
            }
        })
        .catch(error => {
            reject();
        });
    });
}




export function testFunction(str1,str2) {
    console.log("ServiceLogin.js:testFucntion: this test fucntion");
    console.log(str1);
    console.log(str2);
}


