import React, {Component} from "react";

class Footer extends Component {
    render() {
        return (

            <p style={{fontSize:"10px"}}>
            <br/>
            주소 : 서울특별시 금천구 가산디지털1로 145(가산동) 에이스 하이엔드타워 3차 1704호, 17층<br/>
            대표 : 박성용 | 사업자등록번호 : 174-81-00450 | 통신판매신고 : 제2016 창원성산 0063호<br/>
            고객문의 : 1661-8171 (09:00~18:00)
            </p>
        );
    }
}

export default Footer;
