/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from "react";
import * as ServiceLogin from "../service/ServiceLogin";

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.handlerLogout = this.handlerLogout.bind(this);
    }

    handlerLogout = (e) => {
        ServiceLogin.svcLogout();
    }

    render() {
        return (
            <nav className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0">
                <a className="navbar-brand col-sm-3 col-md-2 mr-0" href="#">한국 관리자페이지</a>
                <form className="form-inline" style={{margin:'10px'}}>
                    <button className="btn btn-outline-success" type="button" onClick={this.handlerLogout}>Logout</button>
                </form>
            </nav>
        );
    }
}

export default Navbar;
