import React, {Component} from "react";
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import Footer from "../components/Footer";
import FAQList from "../components/FAQList";
import FAQForm from "../components/FAQForm";
import * as ServiceLogin from "../service/ServiceLogin";

class FAQManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            viewMode : "list",
            faqData : "",
            filter: "",
            filtered:1,
            nowPage: 0,
        }

        this.handlerListFAQs = this.handlerListFAQs.bind(this);
        this.handlerDetailFAQ = this.handlerDetailFAQ.bind(this);
        this.handlerOnChange = this.handlerOnChange.bind(this);
        this.handlerOnSearch = this.handlerOnSearch.bind(this);
        this.handlerOnPage = this.handlerOnPage.bind(this);
        this.handlerAddFaq = this.handlerAddFaq.bind(this);

        this.innerConstructor();
    }

    preRender() {
        console.log("DashBoard.js : preRender : rendering");
    }

    innerConstructor() {
        ServiceLogin.svcLoginCheck();
        console.log("UserManagement.js : innerConstructor : end");
    }


    handlerListFAQs() {
        this.setState({viewMode:"list"});
        this.setState({filter:''})
    }

    handlerDetailFAQ(id) {
        this.setState({viewMode:"detail",faqData:id});
    }
    handlerAddFaq(id) {
        this.setState({viewMode:"add"});
    }
    handlerOnChange(event){
      this.setState({filter:event.target.value});
    }
    handlerOnSearch(event){
      event.preventDefault();
      this.setState({filtered: this.state.filtered+1})
    }
    handlerOnPage(page){
      this.setState({nowPage: page})
    }

    render() {
        this.preRender();
        return (
            <div>
                <NavBar />
                <div className="container-fluid">
                    <div className="row">
                        <SideBar />
                        <main className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
                            { this.state.viewMode === "list" &&
                                <div>
                                    <h2>FAQ 관리</h2>
                                    <div className="col-md-12 text-right mb-3">
                                        <button className="btn btn-outline-primary btn-sm" onClick={this.handlerAddFaq}>FAQ 추가</button><br />
                                    </div>
                                    <form onSubmit={this.handlerOnSearch}>
                                        <div className="input-group mb-3">
                                          <input type="text" className="form-control" placeholder="제목 입력..." value={this.state.filter} onChange={this.handlerOnChange} aria-label="Filter" aria-describedby="basic-addon2"/>
                                             <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" type="submit">검색</button>
                                             </div>
                                        </div>
                                    </form>
                                    <div key={this.state.filtered}>
                                        <FAQList callBackForDetail={this.handlerDetailFAQ} filter={this.state.filter} callBackForPageChange={this.handlerOnPage} pageNow={this.state.nowPage}/>
                                    </div>
                                </div>
                            }
                            {
                                this.state.viewMode === "detail" &&
                                <div>
                                    <h2>FAQ 상세</h2>
                                    <div className="col-md-12 text-right mb-3">
                                        <button className="btn btn-outline-primary btn-sm" onClick={this.handlerListFAQs}>FAQ 내역</button><br />
                                    </div>
                                    <div>
                                        <FAQForm faqData={this.state.faqData}/>
                                    </div>
                                </div>
                            }
                            {
                                this.state.viewMode === "add" &&
                                <div>
                                    <h2>FAQ 추가</h2>
                                    <div className="col-md-12 text-right mb-3">
                                        <button className="btn btn-outline-primary btn-sm" onClick={this.handlerListFAQs}>FAQ 내역</button><br />
                                    </div>
                                    <div>
                                        <FAQForm add={true}/>
                                    </div>
                                </div>
                            }
                        </main>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default FAQManagement;
