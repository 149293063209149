import React, {Component} from "react";

import * as Service from "../service/ServiceLogin";

import "../style/Login.css"

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            passwd: "",
        }

        this.handlerEmail = this.handlerEmail.bind(this);
        this.handlerPassword = this.handlerPassword.bind(this);
    }

    handlerSubmit = (e) => {
        e.preventDefault();
        Service.svcLogin(this.state.email,this.state.passwd)
        .then(result => {
            window.location="/admin/dash";
        })
        .catch(error => {
            console.log("Login.js : handlerSubmit : login failed");
        });
    }

    handlerEmail = (e) => {
        console.log("Login.js:handlerEmail: clicked");
        this.setState({email: e.target.value});
    }

    handlerPassword = (e) => {
        console.log("Login.js:handlerPassword: clicked");
        this.setState({passwd: e.target.value});
    }

    render() {
        return (
            <div className="bgApp">
                <div className="loginCard text-center shadow p-3 mb-5 bg-white rounded">
                    <img src="/asset/logo_v1.png" alt="logo"></img>
                    <h1 className="h3 mb-3 font-weight-normal">Please Sign-In</h1>
                    <form onSubmit={this.handlerSubmit}>
                        <label htmlFor="inputEmail" className="sr-only">Email address</label>
                        <input type="email" id="inputEmail" className="form-control" placeholder="Email address" required autoFocus onChange={this.handlerEmail}></input>
                        <label htmlFor="inputPassword" className="sr-only">Password</label>
                        <input type="password" id="inputPassword" className="form-control" placeholder="Password" required onChange={this.handlerPassword}></input>
                        <button className="loginBtn btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
                    </form>
                </div>
            </div>
        );
    }
}

export default Login;
