export function getInqType(id){
  switch (id) {
    case "0":
      return "A/S"
    case "1":
      return "앱"
    case "2":
      return "디바이스"
    case "3":
      return "계정"
    case "4":
      return "기타"
    case "5":
      return "리워드"
    default:
      return id
  }
}

export function getFaqType(id){
  switch (id+"") {
    case "0":
      return "디바이스"
    case "1":
      return "APP"
    case "2":
      return "눈 운동"
    case "3":
      return "서비스/기타"
    default:
      return id
  }
}

export function shortenString(string, length = 40){
  return string.length <= length ? string : string.substring(0, length)+'...'
}

export function getDoubleDigit(value){
  if(value < 10 && value.length == 1){
    return "0" + value;
  }
  return value;
}
export function formatStringDate(date){
  var item = date.split("-");
  return item[0] + "-" + getDoubleDigit(item[1]) + "-" +getDoubleDigit(item[2]);
}
