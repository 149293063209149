/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from "react";
import * as ServiceNotice from "../service/ServiceNotice";

class NoticeAddForm extends Component {
    constructor(props) {
        super(props);
        this.innerConstructor();
        this.state = {
            title: "",
            content : ""
        }

        this.handlerTitleChange = this.handlerTitleChange.bind(this);
        this.handlerContentChange = this.handlerContentChange.bind(this);
        this.handlerFormSubmit = this.handlerFormSubmit.bind(this);
    }

    innerConstructor() {
        console.log("props"+this.props.NoticeId);
        console.log("UserAddForm.js : innerContstructor : end");
    }

    handlerTitleChange = (e) => {
        this.setState({title: e.target.value});
    }

    handlerContentChange = (e) => {
        this.setState({content: e.target.value});
    }

    handlerFormSubmit = (e) => {
        e.preventDefault();
        if(this.state.title === "") {
            window.alert("title was not empty");
            return;
        }

        var formInfo = {
            id: this.props.NoticeId,
            title: this.state.title,
            content: this.state.content,
            DBId:1
        }

        if(this.props.NoticeId === "") {
            console.log(formInfo);
            ServiceNotice.svcAddNotice(formInfo)
            .then(response => {
                window.location="/notices";
            })
            .catch(error => {
                window.alert("database error : "+error);
            });
        } else {
            ServiceNotice.svcUpdateNotice(formInfo)
            .then(response => {
                window.location="/notices";
            })
            .catch(error => {
                window.alert("database error : "+error);
            });
        }
    }

    componentDidMount() {
        if(this.props.NoticeId !== "") {
            ServiceNotice.svcGetNotice(this.props.NoticeId)
            .then(response => {
                this.setState({title:response.title,content:response.content});
            })
            .catch(error => {
                window.alert("database error : "+error);
            })
        }
    }

    render() {
        return (
            <div className="col-md-8 order-md-1">
                <h4>Notice</h4>
                <form onSubmit={this.handlerFormSubmit}>
                    <label htmlFor="title">Title</label>
                    <input type="text" className="form-control" id="title" value={this.state.title} onChange={this.handlerTitleChange}></input>
                    <br />
                    <label htmlFor="content">Content</label>
                    <textarea className="form-control" id="content" rows="10" onChange={this.handlerContentChange} value={this.state.content}></textarea>
                    <br />
                    {
                        this.props.NoticeId === "" &&
                            <button className="btn btn-primary btn-lg btn-block" type="submit">Add Notice</button>
                    }
                    {
                        this.props.NoticeId !== "" &&
                            <button className="btn btn-primary btn-lg btn-block" type="submit">Update Login Info</button>
                    }
                </form>
            </div>
        );
    }
}

export default NoticeAddForm;
