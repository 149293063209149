import React, {Component} from "react";

class Home extends Component {
    render() {
        console.log("HOME>render:rendering");
        return (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6 bg-secondary">dafsdfa</div>
                        <div>1adfasdfad</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;