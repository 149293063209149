/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from "react";
import * as ServiceNotice from "../service/ServiceNotice";

class NoticeList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            noticelist : [],
        }
        this.handlerOnBtnActivate = this.handlerOnBtnActivate.bind(this);
        this.handlerOnBtnDetail = this.handlerOnBtnDetail.bind(this);
        this.handlerOnBtnEdit = this.handlerOnBtnEdit.bind(this);
        this.handlerOnBtnDel = this.handlerOnBtnDel.bind(this);
        this.handlerOnView = this.handlerOnView.bind(this);
        this.innerConstructor();
    }

    handlerOnBtnActivate(id) {
        console.log("function Activate called"+id);
    }

    handlerOnBtnDetail(id) {
        this.props.callBackForDetail(id);
    }

    handlerOnBtnEdit(id) {
        this.props.callBackForUpdate(id);
    }

    handlerOnView(id) {
        this.props.callBackForView(id);
    }

    handlerOnBtnDel(id) {
        if(window.confirm("this action is not revoked. are you sure?")) {
            ServiceNotice.svcDelNotice(id)
            .then(response => {
                this.innerConstructor();
            })
            .catch(error => {
                console.log("NoticeList.js : handlerOnBtnDel : fetch list error "+error);
            });
        }
    }

    innerConstructor() {
        ServiceNotice.svcNoticeList()
        .then(response => {
            console.log(response);
            this.setState({noticelist: response});
        })
        .catch(error => {
            console.log("NoticeList.js : innerConstructor : fetch list error "+error);
        });
    }

    render() {
        return (
            <div className="table-responsive">
                <table className="table table-striped table-sm">
                    <thead>
                        <tr>
                            <th>NO</th>
                            <th>Title</th>
                            <th>entered date</th>
                            <th>command</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.noticelist.map((val,i) => {
                            return (
                                <tr key={i}>
                                    <td>{i}</td>
                                    <td><a href="#" value={val.id} onClick={(e) => {e.preventDefault();this.handlerOnView(val.id)}}>{val.title}</a></td>
                                    <td>{val.indate}</td>
                                    <td>
                                        <button className="btn btn-outline-primary btn-sm" onClick={() => {this.handlerOnBtnEdit(val.id)}}>Edit</button>&nbsp;
                                        <button className="btn btn-secondary btn-sm" onClick={() => {this.handlerOnBtnDel(val.id)}}>Del</button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default NoticeList;