import React from 'react';
import {Route, BrowserRouter as Router} from "react-router-dom";

import Home from "./screens/Home";
import Login from "./screens/Login";
import DashBoard from "./screens/DashBoard";
import UserManagement from "./screens/UserManagement";
import Notices from "./screens/Notices";
import SerialManagement from "./screens/SerialManagement";
import EventsManagement from "./screens/EventsManagement";
import InquiryManagement from "./screens/InquiryManagement";
import FAQManagement from "./screens/FAQManagement";
import PopNoticeManagement from "./screens/PopNoticeManagement";
import NotificationManagement from './screens/NotificationManagement';

function App() {
  return (
    <Router>
      <main>
        <Route exact path="/admin" component={Home} />
        <Route path="/admin/login" component={Login} />
        <Route path="/admin/dash" component={DashBoard} />
        <Route path="/admin/users" component={UserManagement} />
        <Route path="/admin/notice" component={PopNoticeManagement} />
        <Route path="/admin/events" component={EventsManagement} />
        <Route path="/admin/inquiries" component={InquiryManagement} />
        <Route path="/admin/notices" component={Notices} />
        <Route path="/admin/serials" component={SerialManagement} />
        <Route path="/admin/faqs" component={FAQManagement} />
        <Route path="/admin/notifications" component={NotificationManagement} />
      </main>
    </Router>
  );
}

export default App;
