import {Timestamp} from "firebase/firestore"

export function formatDate(date, fullYear, separator) {
    if(!separator) separator = ".";
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (!fullYear) year = year.toString().substring(2, 4);

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join(separator);
}

export function daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
}

export function timestampToDate(timestamp) {
    return timestamp.toDate()
}

export function dateToTimestamp(date = new Date()) {
    return Timestamp.fromMillis(date.getTime())
}

function twoDigits(value) {
    if (value.toString().length < 2) return '0' + value
    return value
}