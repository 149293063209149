import axios from "axios";
import backServer from "./ServerInfo";
import {auth} from './FirebaseInstance'
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";

function updateJwtKey(jwtKey) {
	sessionStorage.setItem("jKey",jwtKey);
	sessionStorage.setItem("mTime",Date.now());
}

function svcLoginCheckPost(jKey) {
//    console.log("ServiceLogin.js : svcLoginCheckPost : "+jKey);
    return new Promise (function(resolve,reject){
        axios.post(backServer + "/chklogin",
        {},
		{headers:{"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
//            console.log(response.data.status);
            if(response.data.status === "ok") {
                resolve();
            } else {
                window.location="/admin/login";
                reject();
            }
        })
        .catch(error => {
//            console.log("ServiceLogin.js : svcLoginCheckPost : error axios");
            window.location="/admin/login";
            reject();
        });
    });
}

export function testFunction(str1,str2) {
    console.log("ServiceLogin.js:testFucntion: this test fucntion");
    console.log(str1);
    console.log(str2);
}

export function svcLogin(email,password) {
    return new Promise (function(resolve,reject) {
        axios.post(backServer + "/login",{
            email:email,
            password:password,
        })
        .then(response => {
            if(response.data !== "" && response.data.status === "ok") {
                updateJwtKey(response.data.jwtKey);
                signInWithEmailAndPassword(auth, email, password).then().catch((error)=> {
                    
                    if(error.code == 'auth/user-not-found'){
                        console.log("firebase login error", error.code)
                        createUserWithEmailAndPassword(auth, email, password).then().catch()
                    }
                })
                resolve();
            } else {
                reject();
            }
        })
        .catch(error => {
            console.log("axios post error : "+JSON.stringify(error));
            reject();
        });
    });
}

export function svcLogout() {
    console.log("logout");
    sessionStorage.removeItem("jKey");
    sessionStorage.removeItem("mTime");
    window.location="/admin/login";
}

export function svcLoginCheck() {
    if(!sessionStorage.getItem("jKey")) {
        window.location="/admin/login";
    } else {
        svcLoginCheckPost(sessionStorage.getItem("jKey"))
        .then(response => {
//            console.log("key exist");
        })
        .catch(error => {
            window.location="/admin/login";
        });
    }
}
