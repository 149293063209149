import axios from "axios";
import backServer from "./ServerInfo";


export function svcFaqCount(filter) {
    return new Promise(function(resolve,reject){
        axios.post(backServer + "/getfaqcount",
        {
          filter:filter,
          DBId:1
        },
        {headers:{"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            resolve(response.data);
        })
        .catch(error => {
            console.log(error);
        })
    })
}

export function svcFaqList(start,limit,filter) {
  console.log("Filter before send: "+filter)
    return new Promise (function(resolve,reject){
        axios.post(backServer + "/listfaqs",
        {
            start:(start-1)*limit,
            limit:limit,
            filter:filter,
            DBId:1
        },
		{headers:{"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            console.log(response.data);
            if(response.data.status === "fail") {
                console.log("comm not ok");
                reject();
            } else {
                console.log("comm ok");
                console.log(response);
                resolve(response.data);
            }
        })
        .catch(error => {
//            console.log("ServiceLogin.js : svcLoginCheckPost : error axios");
            window.location="/admin/login";
            reject();
        });
    });
}


export function svcAddFaq(value) {
    return new Promise (function(resolve,reject){
        axios.post(backServer + "/addfaq",
        value,
        {headers:{"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            if(response.data.status === "fail") {
                reject();
            } else {
                resolve();
            }
        })
        .catch(error => {
            reject();
        });
    });
}
export function svcUpdateFaq(value) {
  console.log("svcUpdateFaq", value)
    return new Promise (function(resolve,reject){
        axios.post(backServer + "/updatefaq",
        value,
        {headers:{"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            if(response.data.status === "fail") {
                reject();
            } else {
                resolve();
            }
        })
        .catch(error => {
            reject();
        });
    });
}
export function svcDeleteFaq(value) {
    return new Promise (function(resolve,reject){
        axios.post(backServer + "/deletefaq",
        value,
        {headers:{"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            if(response.data.status === "fail") {
                reject();
            } else {
                resolve();
            }
        })
        .catch(error => {
            reject();
        });
    });
}
