/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from "react";
import * as ServicePopNotice from "../service/ServicePopNotice";
import { formatStringDate } from "../utils/StringUtils";

class PopNoticesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            noticelist : null,
            contentCount : 0,
            contentPerPage : 10,
            pageNow : 1,
            lastPage : 1,
        }
        this.handlerOnBtnActivate = this.handlerOnBtnActivate.bind(this);
        this.handlerOnBtnDetail = this.handlerOnBtnDetail.bind(this);
        this.handlerOnBtnEdit = this.handlerOnBtnEdit.bind(this);
        this.handlerOnBtnDel = this.handlerOnBtnDel.bind(this);
        this.handlerOnPage = this.handlerOnPage.bind(this);
        this.innerConstructor();
    }

    handlerOnBtnActivate(id) {
        console.log("function Activate called"+id);
        console.log("in notice list  Toxel")
    }

    handlerOnBtnDetail(id) {
        console.log("Toxel ID: ", id);
        this.props.callBackForDetail(id);
    }

   
    handlerOnBtnEdit(id) {
        this.props.callBackForUpdate(id);
        console.log("function Edit called"+id);
    }

    handlerOnBtnDel(id) {
        if(window.confirm("this action is not revoked. are you sure?")) {
            ServicePopNotice.svcDelPopNotice(id)
            .then(response => {
                console.log(response);
                console.log("function Del called"+id);
                this.innerConstructor();
            })
            .catch(error => {
                console.log("NoticeList.js : handlerOnBtnDel : fetch list error "+error);
            });
        }
    }

    handlerOnPage(page) {
        this.setState({pageNow:page});
        ServicePopNotice.svcPopNoticeList(page,this.state.contentPerPage, this.props.filter)
        .then(response => {
            console.log(response);
            this.setState({noticelist: response});
        })
        .catch(error => {
            console.log("NoticeList.js : innerConstructor : fetch list error "+error);
        });
}

    innerConstructor() {
        // this.props.filter
        ServicePopNotice.svcPopNoticeCount()
        .then(response => {
            console.log("then NoticeCount PopNoticeList");
            this.setState({contentCount:response.count});
            this.setState({lastPage:Math.ceil(response.count/this.state.contentPerPage)});
            // first content count and fetch list

            ServicePopNotice.svcPopNoticeList(this.state.pageNow,this.state.contentPerPage, this.props.filter)
            .then(response => {
                this.setState({noticelist: response});
            })
            .catch(error => {
                console.log("NoticeList.js : innerConstructor : fetch list error "+error);
            });
        })
        .catch(error => {
            console.log("database error "+error);
        });
    }

    render() {
        console.log("Filter: "+this.props.filter)
        var pageNaviNum = [];
        for(var i=1;i<this.state.lastPage+1;i++) {
            pageNaviNum.push(i);
        }
        if(!this.state.noticelist){
          return <div/>
        };

        return (
          <div className="container-fluid">
            <div className="row table-responsive">
                <table className="table table-striped table-sm">
                    <thead>
                        <tr>
                            <th>NO</th>
                            <th>아이디</th>
                            <th>가입일</th>
                            <th>나라</th>
                            <th>보기</th>
                            <th>삭제</th>
                            {/* 수정 */}
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.noticelist.map((val,i) => {

                            return (
                                <tr key={i}>
                                    <td>{i}</td>
                                    <td>{val.id}</td>
                                    <td>{formatStringDate(val.expireDate)}</td>
                                    <td>{val.language}</td>
                                    {/* <td>{val.Country}</td> */}
                                    <td>
                                        <button className="btn btn-outline-secondary btn-sm" onClick={() => {this.handlerOnBtnDetail(val)}}>보기</button>
                                    </td>
                                    <td>
                                        {/* <button className="btn btn-outline-primary btn-sm" onClick={() => {this.handlerOnBtnEdit(val.uid)}}>수정</button>&nbsp; */}
                                        <button className="btn btn-secondary btn-sm" onClick={() => {this.handlerOnBtnDel(val.id)}}>삭제</button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

            </div>
            <div className="row overflow-auto">
            { this.state.lastPage > 1 &&
            <nav aria-label="Page navigation example">
                <ul className="pagination">
                { pageNaviNum.map((val,i) => {
                    return (
                      <>
                            {this.state.pageNow === val && <li key={i} className="page-item"><a className="page-link bg-primary text-white" onClick={() => {this.handlerOnPage(val)}}>{val}</a></li>}
                            {this.state.pageNow !== val &&  <li key={i} className="page-item"><a className="page-link" onClick={() => {this.handlerOnPage(val)}}>{val}</a></li>}
                      </>
                    )
                })}
                </ul>
            </nav>
            }
            </div>
            </div>
        );
    }
}

export default PopNoticesList;
