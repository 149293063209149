/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from "react";

class SideBar extends Component {
    render() {
        return (
            <nav className="col-md-2 d-none d-md-block bg-light sidebar">
                <div className="sidebar-sticky">
                    <ul className="nav flex-column" style={{paddingTop:'20px'}}>
                        <li className="nav-item">
                            <a className="nav-link" href="/admin/dash">
                                <span data-feather="home"></span>
                                대시보드
                                <span className="sr-only">(current)</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/admin/users">
                                <span data-feather="users"></span>
                                회원 관리
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/admin/events">
                                <span data-feather="events"></span>
                                리워드 관리
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/admin/inquiries">
                                <span data-feather="inquiries"></span>
                                문의 관리
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/admin/serials">
                                <span data-feather="serials"></span>
                                시리얼 관리
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/admin/faqs">
                                <span data-feather="faqs"></span>
                                FAQ 관리
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/admin/notice">
                                <span data-feather="notice"></span>
                                팝압 관리
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/admin/notifications">
                                <span data-feather="notif"></span>
                                알림 관리
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}

export default SideBar;
