// KOREA
const backServer = "http://211.178.39.229:8081";

// JAPAN
// const backServer = "http://211.178.39.229:8083";

// TAIWAN
// const backServer = "http://211.178.39.229:8084";

// // China
// const backServer = "http://211.178.39.229:8088";

//USA
// const backServer = "http://211.178.39.229:8086";

export default backServer;