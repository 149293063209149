/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from "react";
import * as ServiceSerial from "../service/ServiceSerial";
var year = (new Date()).getYear() - 100
class SerialAddForm extends Component {
    constructor(props) {
        super(props);
        var monthlist = [1,2,3,4,5,6,7,8,9,10,11,12];
        var daylist = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];
        var today = new Date();

        this.state = {
            yy : today.getFullYear().toString().substring(2,4),
            mm : today.getMonth()+1,
            dd : today.getDate(),
            pname : "P",
            pclass : "W",
            pcol : "W",
            country : "K",
            pusb: "M",
            scount : 1,
            monthlist: monthlist,
            daylist: daylist
        }

        this.innerConstructor();

        this.handlerFormSubmit = this.handlerFormSubmit.bind(this);
        this.handlerGenYearChange = this.handlerGenYearChange.bind(this);
        this.handlerMonth = this.handlerMonth.bind(this);
        this.handlerDay = this.handlerDay.bind(this);
        this.handlerProductNameChange = this.handlerProductNameChange.bind(this);
        this.handlerProductTypeChange = this.handlerProductTypeChange.bind(this);
        this.handlerProductColorChange = this.handlerProductColorChange.bind(this);
        this.handlerCountryChange = this.handlerCountryChange.bind(this);
        this.handlerSerialCountChange = this.handlerSerialCountChange.bind(this);
    }

    innerConstructor() {
    }

    handlerGenYearChange = (e) => {
        this.setState({yy: e.target.value});
    }

    handlerMonth = (e) => {
        this.setState({mm: e.target.value});
    }

    handlerDay = (e) => {
        this.setState({dd: e.target.value});
    }

    handlerProductNameChange = (e) => {
        this.setState({pname: e.target.value});
    }

    handlerProductTypeChange = (e) => {
        this.setState({pclass: e.target.value});
    }

    handlerProductColorChange = (e) => {
        this.setState({pcol: e.target.value});
    }

    handlerCountryChange = (e) => {
        this.setState({country: e.target.value});
    }

    handlerSerialCountChange = (e) => {
        this.setState({scount: e.target.value});
    }

    handlerFormSubmit = (e) => {
        e.preventDefault();
        var formInfo = {
            "yy":this.state.yy,
            "mm":this.state.mm.toString().padStart(2,"0"),
            "dd":this.state.dd.toString().padStart(2,"0"),
            "pname":this.state.pname,
            "pclass":this.state.pclass,
            "pusb": this.state.pusb,
            "pcol":this.state.pcol,
            "country":this.state.country,
            "scount":this.state.scount,
            "DBId":1
        }

        ServiceSerial.svcSerialGeneration(formInfo)
        .then(response => {
            var data = ["code,serial\n"];
            for(var i=0; i<response.length; i++) {
                data.push([response[i].GenCode+","+response[i].SirialNumber+"\n"]);
            }

            var a = document.createElement('a');
            var blob = new Blob(data, {'type':'text/csv'});
            a.href = window.URL.createObjectURL(blob);
            a.download = "serials.csv";
            a.click();
            window.URL.revokeObjectURL(a.href);
            //window.location="/admin/serials";
        })
        .catch(error => {

        });
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="col-md-8 order-md-1">
                <h4>Serial Generation Info</h4>
                <form onSubmit={this.handlerFormSubmit}>
                    <label>Generation Year</label>
                    <div className="row pl-4">
                        <div className="custom-control custom-radio col-md-3">
                            <input id="19" value="19" name="yy" type="radio" className="custom-control-input" required onChange={this.handlerGenYearChange} checked={this.state.yy === "19"}></input>
                            <label className="custom-control-label" htmlFor="19">(20){year-2}</label>
                        </div>
                        <div className="custom-control custom-radio col-md-3">
                            <input id="20" value="20" name="yy" type="radio" className="custom-control-input" required onChange={this.handlerGenYearChange} checked={this.state.yy === "20"}></input>
                            <label className="custom-control-label" htmlFor="20">(20){year-1}</label>
                        </div>
                        <div className="custom-control custom-radio col-md-3">
                            <input id="21" value="21" name="yy" type="radio" className="custom-control-input" required onChange={this.handlerGenYearChange} checked={this.state.yy === "21"}></input>
                            <label className="custom-control-label" htmlFor="21">(20){year}</label>
                        </div>
                        <div className="custom-control custom-radio col-md-3">
                            <input id="22" value="22" name="yy" type="radio" className="custom-control-input" required onChange={this.handlerGenYearChange} checked={this.state.yy === "22"}></input>
                            <label className="custom-control-label" htmlFor="22">(20){year+1}</label>
                        </div>
                    </div>
                    <br />
                    <label htmlFor="email">Generation Date</label>
                    <div className="row pl-4">
                        <div className="col-md-2">
                            <select className="custom-select w-100" id="mm" onChange={this.handlerMonth} defaultValue={this.state.mm}>
                            {
                                this.state.monthlist.map((val,i)=> {
                                    return (
                                        <option value={val} key={i}>{val}</option>
                                    );
                                })
                            }
                            </select>
                        </div> 월
                        <div className="col-md-2">
                            <select className="custom-select w-100" id="dd" onChange={this.handlerDay} defaultValue={this.state.dd}>
                            {
                                this.state.daylist.map((val,i)=> {
                                    return (
                                        <option value={val} key={i}>{val}</option>
                                    );
                                })
                            }
                            </select>
                        </div> 일
                    </div>
                    <br />
                    <label htmlFor="pname">Product Name</label>
                    <div className="row pl-4">
                        <div className="custom-control custom-radio col-md-3">
                            <input id="O" value="O" name="pname" type="radio" className="custom-control-input" required onChange={this.handlerProductNameChange} checked={this.state.pname === "O"}></input>
                            <label className="custom-control-label" htmlFor="O">[O]tus</label>
                        </div>
                        <div className="custom-control custom-radio col-md-3">
                            <input id="P1" value="P" name="pname" type="radio" className="custom-control-input" required onChange={this.handlerProductNameChange} checked={this.state.pname === "P"}></input>
                            <label className="custom-control-label" htmlFor="P1">[P]lus</label>
                        </div>
                    </div>
                    <br />
                    <label htmlFor="pclass">Product Type</label>
                    <div className="row pl-4">
                        <div className="custom-control custom-radio col-md-3">
                            <input id="W" value="W" name="pclass" type="radio" className="custom-control-input" required onChange={this.handlerProductTypeChange} checked={this.state.pclass === "W"}></input>
                            <label className="custom-control-label" htmlFor="W">[W]ellness</label>
                        </div>
                        <div className="custom-control custom-radio col-md-3">
                            <input id="P" value="P" name="pclass" type="radio" className="custom-control-input" required onChange={this.handlerProductTypeChange} checked={this.state.pclass === "P"}></input>
                            <label className="custom-control-label" htmlFor="P">[P]resbyopia</label>
                        </div>
                    </div>
                    <br />
                    <label htmlFor="pcol">Product Color</label>
                    <div className="row pl-4">
                        <div className="custom-control custom-radio col-md-3">
                            <input id="W1" value="W" name="pcol" type="radio" className="custom-control-input" required onChange={this.handlerProductColorChange} checked={this.state.pcol === "W"}></input>
                            <label className="custom-control-label" htmlFor="W1">[W]hite</label>
                        </div>
                        <div className="custom-control custom-radio col-md-3">
                            <input id="S" value="S" name="pcol" type="radio" className="custom-control-input" required onChange={this.handlerProductColorChange} checked={this.state.pcol === "S"}></input>
                            <label className="custom-control-label" htmlFor="S">[S]ilver</label>
                        </div>
                    </div>
                    <br />
                    <label htmlFor="country">Country</label>
                    <div className="row pl-4">
                        <div className="custom-control custom-radio col-md-3">
                            <input id="K" value="K" name="country" type="radio" className="custom-control-input" required onChange={this.handlerCountryChange} checked={this.state.country === "K"}></input>
                            <label className="custom-control-label" htmlFor="K">[K]R</label>
                        </div>
                        <div className="custom-control custom-radio col-md-3">
                            <input id="U" value="U" name="country" type="radio" className="custom-control-input" required onChange={this.handlerCountryChange} checked={this.state.country === "U"}></input>
                            <label className="custom-control-label" htmlFor="U">[U]S</label>
                        </div>
                        <div className="custom-control custom-radio col-md-3">
                            <input id="J" value="J" name="country" type="radio" className="custom-control-input" required onChange={this.handlerCountryChange} checked={this.state.country === "J"}></input>
                            <label className="custom-control-label" htmlFor="J">[J]PN</label>
                        </div>
                        <div className="custom-control custom-radio col-md-3">
                            <input id="T" value="T" name="country" type="radio" className="custom-control-input" required onChange={this.handlerCountryChange} checked={this.state.country === "T"}></input>
                            <label className="custom-control-label" htmlFor="T">[T]WN</label>
                        </div>
                        <div className="custom-control custom-radio col-md-3">
                            <input id="C" value="C" name="country" type="radio" className="custom-control-input" required onChange={this.handlerCountryChange} checked={this.state.country === "C"}></input>
                            <label className="custom-control-label" htmlFor="C">[C]HN</label>
                        </div>
                    </div>
                    <br />
                    <label htmlFor="scount">How Many Serials</label>
                    <input type="number" min="1" className="form-control col-md-3" id="scount" value={this.state.scount} onChange={this.handlerSerialCountChange}></input>
                    <br />
                    <br />
                    <button className="btn btn-primary btn-lg btn-block" type="submit">Generate Serials</button>
                </form>
            </div>
        );
    }
}

export default SerialAddForm;
