/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import * as ServiceUser from "../service/ServiceUser";
import * as TimeUtil from "../utils/TimeUtil";
var today = new Date();
var diseases = ["안질환없음", "근시", "난시", "노안", "원시", "녹내장", "황반변성", "당뇨망막병증", "기타망막질환", "백내장", "사시", "약시", "비문증", "시신경염", "안검하수", "안구건조증", "원추각막"]
var glasses = ["미착용", "착용"]


function getCalendarDays(monthIdx) {
    var _calendardays = []
    today.setMonth(today.getMonth() + monthIdx);
    today.setDate(1);
    console.log("Month: " + today.getMonth())
    var numOfDays = TimeUtil.daysInMonth(today.getMonth() + 1, today.getFullYear());
    console.log(numOfDays)
    var days = [];
    var idx = 0;
    for (var i = 0 - today.getDay(); i < numOfDays; i += 7) {
        var weekdays = []
        for (var j = 0; j < 7; j++) {
            if (i + j < 0 || i + j >= numOfDays) {
                weekdays.push(" ")
            } else {
                weekdays.push(i + j + 1)
            }
        }
        days.push(weekdays)
    }
    return days;
}


class UserDetailForm extends Component {
    constructor(props) {
        super(props);

        var byear = [];
        for (var i = 2020; i > 1900; i--) {
            byear.push(i);
        }

        var joblist = ["회사원", "학생"];

        this.state = {
            id: "",
            name: "",
            phone: "",
            nickName: "",
            members: "",
            gender: "noapply",
            birth: "",
            job: "",
            glass: 1,
            eyehealth: [],
            productserial: "",
            calendardays: getCalendarDays(0),
            monthIdx: 0,
            events: [{ event_id: 0, phone: "01029001957", subscription_date: "2021-02-08", attended_days: 31, attendance_end: "2021-02-08" }],
            attendances: []
        }

        this.handlernickNameChange = this.handlernickNameChange.bind(this);
        this.handleMembersChange = this.handleMembersChange.bind(this);
        this.updateMembers = this.updateMembers.bind(this);
        this.handlerGenderChange = this.handlerGenderChange.bind(this);
        this.handlerbirthChange = this.handlerbirthChange.bind(this);
        this.handlerJobChange = this.handlerJobChange.bind(this);
        this.handlerglassChange = this.handlerglassChange.bind(this);
        this.handlerEyeHealthChange = this.handlerEyeHealthChange.bind(this);
        this.handlerProductSerialChange = this.handlerProductSerialChange.bind(this);
        this.handlerFormSubmit = this.handlerFormSubmit.bind(this);
        this.handlerNext = this.handlerNext.bind(this);

        this.innerConstructor();
    }

    innerConstructor() {
        console.log("props" + this.props.updateUserId);
        console.log("UserAddForm.js rendered");
        console.log()
    }
    dayStyle = (day) => {
        if(day <= 0) return ""
        for(var i = 0; i < this.state.attendances.length; i++){
            const attendanceDate = this.state.attendances[i].date.split(" ")[0]
            const dayStr = TimeUtil.formatDate((new Date(today)).setDate(day), true, "-")
            if(attendanceDate === dayStr ){
                return "bg-primary text-white"
            }
        }
        return ""
    }

    handlerNext = () => {
        this.setState({ calendardays: getCalendarDays(1) });
    }
    handlerPrev = () => {
        this.setState({ calendardays: getCalendarDays(-1) });
    }
    handlernickNameChange = (e) => {
        this.setState({ nickName: e.target.value });
    }

    handleMembersChange = (e) => {
        this.setState({ members: e.target.value })
    }

    handlerGenderChange = (e) => {
        this.setState({ gender: e.target.value });
    }

    handlerbirthChange = (e) => {
        this.setState({ birth: e.target.value });
    }

    handlerJobChange = (e) => {
        this.setState({ job: e.target.value });
    }

    handlerglassChange = (e) => {
        this.setState({ glass: e.target.value });
    }

    handlerEyeHealthChange = (e) => {
        var temparr = this.state.eyehealth;

        var existindex = temparr.indexOf(e.target.value)
        if (existindex !== -1) {
            temparr.splice(existindex, 1);
        } else {
            temparr.push(e.target.value);
        }
        this.setState({ eyehealth: temparr });
    }

    handlerProductSerialChange = (e) => {
        this.setState({ productserial: e.target.value });
    }

    handlerFormSubmit = (e) => {
        e.preventDefault();
        var strEyeHealth = "";
        for (var i = 0; i < this.state.eyehealth.length; i++) {
            if (i === 0) {
                strEyeHealth = this.state.eyehealth[i];
            } else {
                strEyeHealth = strEyeHealth + "," + this.state.eyehealth[i];
            }
        }
        var data = {
            uid: this.props.updateUserId,
            nickName: this.state.nickName,
            gender: this.state.gender,
            birth: this.state.birth,
            job: this.state.job,
            glass: this.state.glass,
            eyehealth: strEyeHealth,
            productserial: this.productserial,
            DBId: 1
        }

        ServiceUser.svcAddUserDetail(data)
            .then(response => {
                window.alert("okay");
            })
            .catch(error => {
                //            window.alert("database error : "+error);
            });
    }



    updateMembers = () => {
        if (window.confirm("are you sure?")) {
            ServiceUser.svcUpdateMembers(this.props.updateUserId, this.state.members)
                .then(response => {
                    console.log("member updated response", response)
                })
                .catch(error => {
                    console.log("database error: " + JSON.stringify(error))
                })
        }
    }



    componentDidMount() {
        if (this.props.updateUserId !== "") {
            ServiceUser.svcGetUserDetail(this.props.updateUserId)
                .then(response => {
                    var eyehealth = response.Disease.split(",");
                    console.log("response", response);
                    this.setState({
                        id: response.id,
                        nickName: response.Nickname,
                        name: response.Name,
                        gender: response.Sex,
                        birth: response.Birth,
                        job: response.Job,
                        phone: response.Phone,
                        glass: response.Glass,
                        eyehealth: eyehealth,
                        productserial: response.SirialNumber,
                        members: response.Members == 0 ? 4 : response.Members
                    });
                })
                .catch(error => {
                    console.log("database error: " + JSON.stringify(error));
                    //window.alert("database error : "+error);
                });
            ServiceUser.svcGetAttendances(this.props.updateUserId)
                .then(response => {
                    console.log("attendances", response)
                    if (response && response.length > 0) {
                        this.setState({
                            attendances: response
                        })
                    }

                });
            ServiceUser.svcGetEventsData(this.props.updateUserId)
                .then(response => {
                    this.setState({
                        events: response
                    })
                })
        }

    }

    render() {
        return (
            <div className="col-md-8 order-md-1">
                <div>
                    <h4>회원 정보</h4>
                    <label htmlFor="id">아이디</label>
                    <input type="text" className="form-control" id="id" value={this.state.id} onChange={this.handlerNickNameChange} readOnly></input>
                    <br />
                    <label htmlFor="nickName">닉네임</label>
                    <input type="text" className="form-control" id="nickName" value={this.state.nickName} onChange={this.handlernickNameChange} readOnly></input>
                    <br />
                    <label>최대 회원 횟수</label>
                    <div className="input-group mb-3">
                        <input className="form-control" placeholder="회원 횟수 입력하세요" id="members" value={this.state.members} type="number" onChange={this.handleMembersChange}></input>
                        <div className="input-group-append">
                            <button className="btn btn-outline-secondary btn-sm" type="button" onClick={this.updateMembers}>수정</button>
                        </div>
                    </div>
                    <br />
                    <label>성별</label>
                    <div className="custom-control custom-radio">
                        <input id="male" value="male" name="gender" type="radio" className="custom-control-input" required onChange={this.handlerGenderChange} checked={this.state.gender === 1}></input>
                        <label className="custom-control-label" htmlFor="male">남</label>
                    </div>
                    <div className="custom-control custom-radio">
                        <input id="female" value="female" name="gender" type="radio" className="custom-control-input" required onChange={this.handlerGenderChange} checked={this.state.gender === 2}></input>
                        <label className="custom-control-label" htmlFor="female">여</label>
                    </div>
                    <br />
                    <label htmlFor="phone">전화번호</label>
                    <input type="text" className="form-control" id="phone" value={this.state.phone} onChange={this.handlerPhoneChange} readOnly></input>
                    <br />
                    <label htmlFor="birth">출생연도</label>
                    <input type="text" className="form-control" id="birth" value={this.state.birth} onChange={this.handlerBirthChange} readOnly></input>
                    <br />
                    <label htmlFor="phone">직업</label>
                    <input type="text" className="form-control" id="job" value={this.state.job} onChange={this.handlerJobChange} readOnly></input>
                    <br />
                    <label htmlFor="glass">안경 착용 여부</label>
                    <input type="text" className="form-control" id="glass" value={glasses[this.state.glass - 1]} onChange={this.handlerglassChange} readOnly></input>
                    <br />
                    <label>안질환</label>
                    <div className="row pl-4">
                        {diseases.map((val, i) => {
                            return (
                                <div className="custom-control custom-checkbox col-md-4" key={i}>
                                    <input type="checkbox" value={val} className="custom-control-input" id={"eyehealth" + i} onChange={this.handlerEyeHealthChange} checked={this.state.eyehealth.includes(val) === true}></input>
                                    <label className="custom-control-label" htmlFor={"eyehealth" + i}>{val}</label>
                                </div>
                            );
                        })}
                    </div>

                    <br />
                    <label htmlFor="productserial">시리얼번호</label>
                    <input type="text" className="form-control" id="productserial" value={this.state.productserial} onChange={this.handlerProductSerialChange} readOnly></input>
                    <br /><br />
                </div>
                <h4>운동 기록</h4>
                <div className="row">

                    <a className="col-1 align-self-center" onClick={this.handlerPrev}><i className="fa fa-chevron-left" style={{ fontSize: "48px", color: "black" }} /></a>
                    <div className="col" style={{ height: "350px" }}>
                        <div className="mt-3 text-center">{today.getFullYear() + "-" + (today.getMonth() + 1)}</div>
                        <table className="table table-striped table-sm mt-1 text-center">
                            <thead>
                                <tr>
                                    <th>일</th>
                                    <th>월</th>
                                    <th>화</th>
                                    <th>수</th>
                                    <th>목</th>
                                    <th>금</th>
                                    <th>토</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.calendardays.map((val, i) => {
                                    return (
                                        <tr key={i}>
                                            <td className={this.dayStyle(val[0])}>{val[0]}</td>
                                            <td className={this.dayStyle(val[1])}>{val[1]}</td>
                                            <td className={this.dayStyle(val[2])}>{val[2]}</td>
                                            <td className={this.dayStyle(val[3])}>{val[3]}</td>
                                            <td className={this.dayStyle(val[4])}>{val[4]}</td>
                                            <td className={this.dayStyle(val[5])}>{val[5]}</td>
                                            <td className={this.dayStyle(val[6])}>{val[6]}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <a className="col-1 align-self-center" onClick={this.handlerNext}><i className="fa fa-chevron-right" style={{ fontSize: "48px", color: "black" }} /></a>
                </div>

                <h4 className="mb-3">리워드 신청</h4>
                {this.state.events &&
                    <table className="table table-striped table-sm mt-1 text-center">
                        <thead>
                            <tr >
                                <th>No</th>
                                <th>Event 아이디</th>
                                <th>전화번호</th>
                                <th>신청일</th>
                                <th>마지막 출속일</th>
                                <th>출속 횟수</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.events.map((val, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{i}</td>
                                            <td>{val.event_id}</td>
                                            <td>{val.phone}</td>
                                            <td>{val.subscription_date}</td>
                                            <td>{val.attendance_end}</td>
                                            <td>{val.attended_days}</td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                }
            </div>
        );
    }
}

export default UserDetailForm;
