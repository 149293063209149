import React, {Component} from "react";
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import Footer from "../components/Footer";
import EventsList from "../components/EventsList";
import UserAddForm from "../components/UserAddForm";
import UserDetailForm from "../components/UserDetailForm";
import * as ServiceLogin from "../service/ServiceLogin";

class EventsManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            viewMode : "list",
            updateUserId : "",
            filter: "",
            filtered:1
        }

        this.handlerListEvents = this.handlerListEvents.bind(this);
        this.handlerUpdateUser = this.handlerUpdateUser.bind(this);
        this.handlerDetailUser = this.handlerDetailUser.bind(this);
        this.handlerOnChange = this.handlerOnChange.bind(this);
        this.handlerOnSearch = this.handlerOnSearch.bind(this);

        this.innerConstructor();
    }

    preRender() {
        console.log("DashBoard.js : preRender : rendering");
    }

    innerConstructor() {
        ServiceLogin.svcLoginCheck();
        console.log("UserManagement.js : innerConstructor : end");
    }


    handlerListEvents() {
        this.setState({viewMode:"list"});
        this.setState({filter:''})
    }

    handlerUpdateUser(id) {
        this.setState({viewMode:"add",updateUserId:id});
    }

    handlerDetailUser(id) {
        this.setState({viewMode:"detail",updateUserId:id});
    }
    handlerOnChange(event){
      this.setState({filter:event.target.value});
    }
    handlerOnSearch(event){
      event.preventDefault();
      this.setState({filtered: this.state.filtered+1})
    }

    render() {
        this.preRender();
        return (
            <div>
                <NavBar />
                <div className="container-fluid">
                    <div className="row">
                        <SideBar />
                        <main className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
                            { this.state.viewMode === "list" &&
                                <div>
                                    <h2>리워드 관리</h2>
                                    <form onSubmit={this.handlerOnSearch}>
                                        <div className="input-group mb-3">
                                          <input type="text" className="form-control" placeholder="아이디, 전화번호 또는 날짜 입력..." value={this.state.filter} onChange={this.handlerOnChange} aria-label="Filter" aria-describedby="basic-addon2"/>
                                             <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" type="submit">검색</button>
                                             </div>
                                        </div>
                                    </form>
                                    <div key={this.state.filtered}>
                                        <EventsList callBackForUpdate={this.handlerUpdateUser} callBackForDetail={this.handlerDetailUser} filter={this.state.filter}/>
                                    </div>
                                </div>
                            }
                            {
                                this.state.viewMode === "add" &&
                                <div>
                                    <h2>User Add</h2>
                                    <div className="col-md-12 text-right mb-3">
                                        <button className="btn btn-outline-primary btn-sm" onClick={this.handlerListEvents}>Go To List</button><br />
                                    </div>
                                    <div>
                                        <UserAddForm updateUserId={this.state.updateUserId}/>
                                    </div>
                                </div>
                            }
                            {
                                this.state.viewMode === "detail" &&
                                <div>
                                    <h2>Detail User info</h2>
                                    <div className="col-md-12 text-right mb-3">
                                        <button className="btn btn-outline-primary btn-sm" onClick={this.handlerListEvents}>Go To List</button><br />
                                    </div>
                                    <div>
                                        <UserDetailForm updateUserId={this.state.updateUserId}/>
                                    </div>
                                </div>
                            }
                        </main>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default EventsManagement;
