import axios from "axios";
import * as ServiceSerial from "../service/ServiceSerial";
import * as ServiceInquiries from "../service/ServiceInquiries";
import * as ServiceEvents from "../service/ServiceEvents";
import backServer from "./ServerInfo";

export function svcUserCount(filter) {
    console.log("svcUserCount", new Date().getTime())
    return new Promise(function(resolve,reject){
        axios.post(backServer + "/getusercount",
        {
          filter:filter,
          DBId:1
        },
        {headers:{"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            resolve(response.data);
        })
        .catch(error => {
            console.log(error);
        })
    })
}

export function svcUserList(start,limit,filter) {
    console.log("svcUserList", new Date().getTime())
    return new Promise (function(resolve,reject){
        axios.post(backServer + "/listuser",
        {
            start:(start-1)*limit,
            limit:limit,
            filter:filter,
            DBId:1
        },
		{headers:{"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            console.log(response.data);
            if(response.data.status === "fail") {
                console.log("comm not ok");
                reject();
            } else {
                console.log("comm ok");
                console.log(response);
                resolve(response.data);
            }
        })
        .catch(error => {
//            console.log("ServiceLogin.js : svcLoginCheckPost : error axios");
            window.location="/admin/login";
            reject();
        });
    });
}

export function svcAddUser(value) {
    return new Promise (function(resolve,reject){
        axios.post(backServer + "/adduser",
        {
            email:value.email,
            password:value.pwd,
            DBId:1
        },
        {headers:{"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            if(response.data.status === "fail") {
                reject();
            } else {
                resolve();
            }
        })
        .catch(error => {
            reject();
        });
    });
}

export function svcAddUserDetail(value) {
    return new Promise (function(resolve,reject){
        axios.post(backServer + "/adduserdetail",
        value,
        {headers:{'Content-Type': 'application/json',"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            if(response.data.status === "fail") {
                reject();
            } else {
                resolve();
            }
        })
        .catch(error => {
            reject();
        });
    });
}

export function svcUpdateUser(value) {
    return new Promise (function(resolve,reject){
        axios.post(backServer + "/updateuser",
        {
            email:value.email,
            password:value.pwd,
            DBId:1
        },
        {headers:{"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            if(response.data.status === "fail") {
                reject();
            } else {
                resolve();
            }
        })
        .catch(error => {
            reject();
        });
    });
}

export function svcDelUser(value) {
    let data = JSON.stringify({
        id:value,
        DBId:1
    });
    return new Promise (function(resolve,reject){
        axios.post(backServer + "/deluser",
        data,
		{headers:{'Content-Type': 'application/json',"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            console.log(response.data);
            if(response.data.status === "fail") {
                reject();
            } else {
                resolve();
            }
        })
        .catch(error => {
            reject();
        });
    });
}

export function svcGetUser(value) {
    let data = JSON.stringify({
        uid:value,
        DBId:1
    });
    return new Promise (function(resolve,reject){
        axios.post(backServer + "/getuser",
        data,
		{headers:{'Content-Type': 'application/json',"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            console.log(response.data);
            if(response.data.status === "fail") {
                reject();
            } else {
                resolve(response.data);
            }
        })
        .catch(error => {
            reject();
        });
    });
}

export function svcGetUserDetail(value) {
    let data = JSON.stringify({
        uid:value,
        DBId:1
    });
    return new Promise (function(resolve,reject){
        axios.post(backServer + "/getuserdetail",
        data,
		{headers:{'Content-Type': 'application/json',"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            console.log(response.data);
            if(response.data.uid !== 0) {
                resolve(response.data);
            } else {
                reject(response.data);
            }
        })
        .catch(error => {
            reject();
        });
    });
}

export function svcGetGameData(value) {

    let data = JSON.stringify({
        uid: value,
        DBId:1
    });
    return new Promise(function (resolve, reject) {
        axios.post(backServer + "/getgamedata",
            data,
            { headers: { 'Content-Type': 'application/json', "Authorization": "Bearer "+sessionStorage.getItem("jKey") } })
            .then(response => {
              //  console.log(response.data);
                if (response.data.status === "fail") {
                    reject();
                } else {
                    resolve(response.data);
                }
            })
            .catch(error => {
                //console.log("GameData Rejected: " + error);
                reject();
            });
    });
}

export function svcGetAttendances(value) {

    let data = JSON.stringify({
        uid: value,
        DBId:1
    });
    return new Promise(function (resolve, reject) {
        axios.post(backServer + "/getattendances",
            data,
            { headers: { 'Content-Type': 'application/json', "Authorization": "Bearer "+sessionStorage.getItem("jKey") } })
            .then(response => {
              //  console.log(response.data);
                if (response.data.status === "fail") {
                    reject();
                } else {
                    resolve(response.data);
                }
            })
            .catch(error => {
                //console.log("GameData Rejected: " + error);
                reject();
            });
    });
}

export function svcGetEventsData(value) {

    let data = JSON.stringify({
        uid: value,
        DBId:1
    });
    return new Promise(function (resolve, reject) {
        axios.post(backServer + "/geteventsdata",
            data,
            { headers: { 'Content-Type': 'application/json', "Authorization": "Bearer "+sessionStorage.getItem("jKey") } })
            .then(response => {
              //  console.log(response.data);
                if (response.data.status === "fail") {
                    reject();
                } else {
                    resolve(response.data);
                }
            })
            .catch(error => {
                //console.log("GameData Rejected: " + error);
                reject();
            });
    });
}

export function svcGetUsersGameData(dateMin, dateMax, uid, gameId) {

    let data = JSON.stringify({
        uid: uid,
        dateMin: dateMin,
        dateMax: dateMax,
        gameId: gameId,
        DBId:1
    });
    return new Promise(function (resolve, reject) {
        axios.post(backServer + "/getusersgamedata",
            data,
            { headers: { 'Content-Type': 'application/json', "Authorization": "Bearer "+sessionStorage.getItem("jKey") } })
            .then(response => {
              //  console.log(response.data);
                if (response.data.status === "fail") {
                    reject();
                } else {
                    resolve(response.data);
                }
            })
            .catch(error => {
                //console.log("GameData Rejected: " + error);
                reject();
            });
    });
}


export function testFunction(str1,str2) {
    console.log("ServiceLogin.js:testFucntion: this test fucntion");
    console.log(str1);
    console.log(str2);
}



export function svcUpdateMembers(uid, members) {
    return new Promise (function(resolve,reject){
        axios.post(backServer + "/updatemembers",
        {
            uid: uid,
            members:members,
            DBId:1
        },
        {headers:{"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            if(response.data.status === "fail") {
                reject();
            } else { 
                resolve();
            }
        })
        .catch(error => {
            reject();
        });
    });}
