/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from "react";
import * as ServiceFAQs from "../service/ServiceFAQs";
import * as StringUtils from "../utils/StringUtils";

const checktxts = ["Check", "Uncheck"]
const rewardtxts = ["Not Rewarded", "Rewarded"]

class FAQList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            faqlist : null,
            contentCount : 0,
            contentPerPage : 10,
            pageNow : 1,
            lastPage : 1,
        }

        this.handlerOnBtnDetail = this.handlerOnBtnDetail.bind(this);
        this.handlerOnBtnDelete = this.handlerOnBtnDelete.bind(this);
        this.handlerOnPage = this.handlerOnPage.bind(this);
        this.innerConstructor();
    }

    handlerOnBtnDetail(id) {
        console.log("UID: "+id);
        this.props.callBackForDetail(id);
    }

    handlerOnPage(page) {
        this.setState({pageNow:page});
        this.props.callBackForPageChange(page);
        ServiceFAQs.svcFaqList(page,this.state.contentPerPage, this.props.filter)
        .then(response => {
            this.setState({faqlist: response});
        })
        .catch(error => {
            console.log("faqlist.js : innerConstructor : fetch list error "+error);
        });
    }

    handlerOnBtnDelete(val) {
      if(window.confirm("FAQ 삭제하시겠습니까?")) {
        ServiceFAQs.svcDeleteFaq(val)
        .then(response => {
            window.alert("삭제됐습니다");
            window.location = "/admin/faqs"
        })
        .catch(error => {
            console.log("faqlist.js : innerConstructor : fetch list error "+error);
        });
      }
    }

    innerConstructor() {
        ServiceFAQs.svcFaqCount(this.props.filter)
        .then(response => {
            this.setState({contentCount:response.count});
            this.setState({lastPage:Math.ceil(response.count/this.state.contentPerPage)});
            // first content count and fetch list
            ServiceFAQs.svcFaqList(this.state.pageNow,this.state.contentPerPage, this.props.filter)
            .then(response => {
                this.setState({faqlist: response});
            })
            .catch(error => {
                console.log("faqlist.js : innerConstructor : fetch list error "+error);
            });
        })
        .catch(error => {
            console.log("database error "+error);
        });
    }

    render() {
        console.log("Filter: "+this.props.filter)
        var pageNaviNum = [];
        for(var i=1;i<this.state.lastPage+1;i++) {
            pageNaviNum.push(i);
        }
        if(!this.state.faqlist){
          return <div/>
        };

        return (
          <div className="container-fluid">
            <div className="row table-responsive">
                <table className="table table-striped table-sm">
                    <thead>
                        <tr>
                            <th>NO</th>
                            <th>유형</th>
                            <th>문의</th>
                            <th>답변</th>
                            <th>언어</th>
                            <th>보기/삭제</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.faqlist.map((val,i) => {

                            return (
                                <tr key={i}>
                                    <td>{i}</td>
                                    <td>{StringUtils.getFaqType(val.type)}</td>
                                    <td>{val.inquiry.substring(0, val.inquiry.length < 50 ? val.inquiry.length -1: 50)}...</td>
                                    <td>{val.reply.substring(0, val.reply.length < 50 ? val.reply.length -1: 50)}...</td>
                                    <td>{val.language}</td>
                                    <td>
                                        <button className="btn btn-outline-primary btn-sm" onClick={() => {this.handlerOnBtnDetail(val)}}>보기</button>&nbsp;
                                        <button className="btn btn-outline-danger btn-sm" onClick={() => {this.handlerOnBtnDelete(val)}}>삭제</button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

            </div>
            <div className="row overflow-auto">
            { this.state.lastPage > 1 &&
            <nav aria-label="Page navigation example">
                <ul className="pagination">
                { pageNaviNum.map((val,i) => {
                    return (
                      <>
                            {this.state.pageNow === val && <li key={i} className="page-item"><a className="page-link bg-primary text-white" onClick={() => {this.handlerOnPage(val)}}>{val}</a></li>}
                            {this.state.pageNow !== val &&  <li key={i} className="page-item"><a className="page-link" onClick={() => {this.handlerOnPage(val)}}>{val}</a></li>}
                      </>
                    )
                })}
                </ul>
            </nav>
            }
            </div>
            </div>
        );
    }
}

export default FAQList;
