/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from "react";
import * as ServiceInquiries from "../service/ServiceInquiries";
import * as ServiceUser from "../service/ServiceUser";
import * as ServiceNotification from "../service/ServiceNotifications";
import * as TimeUtil from "../utils/TimeUtil";
import * as StringUtils from "../utils/StringUtils";
import { profileIcons } from "../constants";

var today = new Date();

class InquiryForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userinfo:{},
            reply: "",
            reply_date: TimeUtil.formatDate(new Date(), true, '-'),
            messages: [],

        }

        this.handlerOnReplyChange = this.handlerOnReplyChange.bind(this);
        this.handlerFormSubmit = this.handlerFormSubmit.bind(this);

        this.innerConstructor();
    }

    innerConstructor() {
        console.log("InquiryForm.js : innerContstructor : end");
        console.log()
    }

    handlerOnReplyChange = (e) => {
        this.setState({reply: e.target.value});
    }

    handlerFormSubmit = (e) => {
        e.preventDefault();
        if(!this.props.inquiryData.response){
            var data = {
                dataid: this.props.inquiryData.dataid,
                response: this.state.reply,
                reply_date : this.state.reply_date,
                DBId:1
            }

            ServiceInquiries.svcAddReply(data)
           .then(response => {
                window.location="/admin/inquiries";
                window.alert("Reply updated");
           })
           .catch(error => {
                window.alert("Couldn't update : "+error);
           });
      }else{
        var data = {
          uid: 0,
          inquiry_id: this.props.inquiryData.dataid,
          message: this.state.reply,
          date: TimeUtil.formatDate(new Date(), true, '-'),
        }
        this.setState({messages: [...this.state.messages, data]})
        console.log("reply chat", data)
        ServiceInquiries.svcReplyChat(data)
       .then(response => {
            this.setState({reply: ""})
       })
       .catch(error => {
            window.alert("Couldn't send message : "+error);
       });
      }
      const notification = {
        type:'message',
        uid: this.props.inquiryData.uid,
        title: `RE: ${this.props.inquiryData.title}`,
        body: this.state.reply,
        icon: profileIcons[this.state.userinfo.ProfilePic],
        link:`otusplus://CustomerService?initialRouteName=InquiryList&inquiryId=${this.props.inquiryData.dataid}`,
      }
      console.log("notification", notification)
      console.log("userInfo", this.state.userinfo)
      ServiceNotification.svcNotifyByToken(notification, this.state.userinfo.NotifToken)

    }

    componentDidMount() {
      // this.setState({reply:this.props.inquiryData.response})
      ServiceUser.svcGetUserDetail(this.props.inquiryData.uid).
      then(response => {
        this.setState({userinfo:response});
      })

      const _messages = [{uid: this.props.inquiryData.uid, message: this.props.inquiryData.content, attach_url: this.props.inquiryData.attach_url}]
      if(this.props.inquiryData.response)
        _messages.push({uid: 0, message: this.props.inquiryData.response})
      this.setState({messages: _messages})
      ServiceInquiries.svcInquiryChats(this.props.inquiryData.dataid).then(response => {
        this.setState({messages: [...this.state.messages, ...response]})
      })
    }

    render() {
      if(!this.props.inquiryData || !this.state.userinfo) {
          return<div/>;
      }
        return (
            <div className="col-md-8 order-md-1">
            <table className="table table-striped table-sm">
                <thead>
                    <tr>
                        <th>아이디</th>
                        <th>닉네임</th>
                        <th>문의 유형</th>
                        <th>제목</th>
                        <th>이메일</th>
                    </tr>
                </thead>
                <tbody>
                      <tr>
                        <td>{this.state.userinfo.id}</td>
                        <td>{this.state.userinfo.Nickname}</td>
                        <td>{StringUtils.getInqType(this.props.inquiryData.type)}</td>
                        <td>{this.props.inquiryData.title}</td>
                        <td>{this.props.inquiryData.email}</td>
                      </tr>
                </tbody>
            </table>
            <br/>
               {false && <div>
                <h5>Question</h5>
                    <div className="input-group">
                        <textarea style={{height:"200px"}} className="form-control" value={this.props.inquiryData.content} aria-label="With textarea" readOnly></textarea>
                    </div>
                <br/>
                <br/>
                <h5>Response</h5>
                <form onSubmit={this.handlerFormSubmit}>
                    <div className="input-group">
                        <textarea style={{height:"200px"}} className="form-control" aria-label="With textarea" value={this.state.reply} onChange={this.handlerOnReplyChange}></textarea>
                        <div className="input-group-prepend">
                        </div>
                    </div>
                    <br/>
                    <button className="btn btn-outline-primary btn-lg btn-block" type="submit">Reply</button>
                </form>
                </div>
              }
              <div style={{overflow:'scroll', height:'500px'}}>
                {this.state.messages.map((value, idx)=>{
                  return (<div style={{backgroundColor: "#fff"}}>
                       {value.uid === 0 &&
                          <div style={{width:'49%', marginLeft:'51%', backgroundColor:"#a4a4a4" , padding:"20px", marginBottom:"20px", borderRadius:25}}>
                            <p3 style={{color:"#fff"}}>{value.message}</p3>

                         </div>}

                      {value.uid !== 0 &&
                          <div style={{width:'49%', backgroundColor:"#60a3bc", padding:'20px', marginBottom:"20px", borderRadius:25}}>
                            <p3 style={{color:"#fff"}}>{value.message}</p3>
                            {value.attach_url && <img src={value.attach_url} style={{width:'99%', height:'auto', borderRadius:5, marginTop:10}}></img>}
                          </div>}
                       </div>)
                }
                )}
                </div>
                <div style={{ bottom:0, backgroundColor:"#a4a4a4", padding:"10px", width:'100%', borderRadius:20}}>
                <form onSubmit={this.handlerFormSubmit}>
                    <div className="input-group" style={{alignItems:'center'}}>
                        <textarea style={{height:"100px", borderRadius:20}} placeholder="내용을 입력해주세요..." className="form-control" aria-label="With textarea" value={this.state.reply} onChange={this.handlerOnReplyChange}></textarea>
                        <button type="submit"  class="btn btn-primary" style={{marginLeft:20, height:'40px'}}>작성 완료</button>
                    </div>
                </form>
                </div>


            </div>
        );
    }
}

export default InquiryForm;
