import axios from "axios";
import backServer from "./ServerInfo";


export function svcEventsCount(filter) {
    return new Promise(function(resolve,reject){
        axios.post(backServer + "/geteventcount",
        {
          filter:filter,
          DBId:1
        },
        {headers:{"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            resolve(response.data);
        })
        .catch(error => {
            console.log(error);
        })
    })
}

export function svcEventsList(start,limit,filter) {
  console.log("Filter before send: "+filter)
    return new Promise (function(resolve,reject){
        axios.post(backServer + "/listevents",
        {
            start:(start-1)*limit,
            limit:limit,
            filter:filter,
            DBId:1
        },
		{headers:{"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            console.log(response.data);
            if(response.data.status === "fail") {
                console.log("comm not ok");
                reject();
            } else {
                console.log("comm ok");
                console.log(response);
                resolve(response.data);
            }
        })
        .catch(error => {
          // console.log("ServiceLogin.js : svcLoginCheckPost : error axios");
          // console.log("database error "+error);
            window.location="/admin/login";
            reject();
        });
    });
}


export function svcCheckEvent(id, value) {
    return new Promise (function(resolve,reject){
        axios.post(backServer + "/checkevent",
        {
            id:id,
            rewarded:value,
            DBId:1
        },
        {headers:{"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            if(response.data.status === "fail") {
                reject();
            } else {
                resolve();
            }
        })
        .catch(error => {
            reject();
        });
    });
}
