/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from "react";
import * as ServiceUser from "../service/ServiceUser";

class UserAddForm extends Component {
    constructor(props) {
        super(props);
        this.innerConstructor();
        this.state = {
            email: "",
            pwd : "",
            cpwd : ""
        }

        this.handlerEmailChange = this.handlerEmailChange.bind(this);
        this.handlerPwdChange = this.handlerPwdChange.bind(this);
        this.handlerCpwdChange = this.handlerCpwdChange.bind(this);
        this.handlerFormSubmit = this.handlerFormSubmit.bind(this);
    }

    innerConstructor() {
        console.log("props"+this.props.updateUserId);
        console.log("UserAddForm.js : innerContstructor : end");
    }

    handlerEmailChange = (e) => {
        this.setState({email: e.target.value});
    }

    handlerPwdChange = (e) => {
        this.setState({pwd: e.target.value});
    }

    handlerCpwdChange = (e) => {
        this.setState({cpwd: e.target.value});
    }

    handlerFormSubmit = (e) => {
        e.preventDefault();
        if(this.state.pwd === "" || this.state.cpwd === "") {
            window.alert("please input password");
            return;
        }

        if(this.state.pwd !== this.state.cpwd) {
            window.alert("password is not same confirm password");
            return;
        }

        var formInfo = {
            email: this.state.email,
            pwd: this.state.pwd
        }

        if(this.props.updateUserId === "") {
            ServiceUser.svcAddUser(formInfo)
            .then(response => {
                window.location="/users";
            })
            .catch(error => {
                window.alert("database error : "+error);
            });
        } else {
            ServiceUser.svcUpdateUser(formInfo)
            .then(response => {
                window.location="/users";
            })
            .catch(error => {
                window.alert("database error : "+error);
            });
        }
    }

    componentDidMount() {
        if(this.props.updateUserId !== "") {
            ServiceUser.svcGetUser(this.props.updateUserId)
            .then(response => {
                this.setState({email:response.uid});
            })
            .catch(error => {
                window.alert("database error : "+error);
            })
        }
    }

    render() {
        return (
            <div className="col-md-8 order-md-1">
                <h4>User Info</h4>
                <form onSubmit={this.handlerFormSubmit}>
                    <label htmlFor="email">Email</label>
                    {
                        this.props.updateUserId === "" &&
                        <input type="email" className="form-control" id="email" placeholder="you@example.com" onChange={this.handlerEmailChange}></input>
                    }
                    {
                        this.props.updateUserId !== "" &&
                        <input type="email" className="form-control" id="email" placeholder="you@example.com" value={this.state.email} readOnly></input>
                    }
                    <div className="invalid-feedback">
                        Please enter a valid email address for shipping updates.
                    </div>
                    <br />
                    <label htmlFor="email">Password</label>
                    <input type="password" className="form-control" id="pwd" placeholder="enter your password" onChange={this.handlerPwdChange}></input>
                    <label htmlFor="email">Confirm Password</label>
                    <input type="password" className="form-control" id="cpwd" placeholder="confirm your password" onChange={this.handlerCpwdChange}></input>
                    <br />
                    {
                        this.props.updateUserId === "" &&
                            <button className="btn btn-primary btn-lg btn-block" type="submit">Add User</button>
                    }
                    {
                        this.props.updateUserId !== "" &&
                            <button className="btn btn-primary btn-lg btn-block" type="submit">Update Login Info</button>
                    }
                </form>
            </div>
        );
    }
}

export default UserAddForm;