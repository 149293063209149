import React, { Component } from "react";
import * as ServicePopNotice from "../service/ServicePopNotice";

class PopNoticeDetailForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: "",
            image: "",
            language: "",
            expireDate: "",
        }

        this.handlerImageChange = this.handlerImageChange.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
        this.updateExpireDate = this.updateExpireDate.bind(this);
        this.handlerFormSubmit = this.handlerFormSubmit.bind(this);

        this.innerConstructor();
    }

    innerConstructor() {
        console.log("props", this.props);
        console.log()
    }

    handlerImageChange = (e) => {
        this.setState({ image: e.target.value });
    }

    handleLanguageChange = (e) => {
        this.setState({ language: e.target.value })
    }

    updateExpireDate = (e) => {
        this.setState({ expireDate: e.target.value })
    }

    handlerFormSubmit = (e) => {
        console.log("in submit handler")
        e.preventDefault();
        if(this.state.image === "" || this.state.language === "" || this.state.expireDate ==="" || this.state.id === "") {
            window.alert("please enter language andd image link");
            return;
        }
        var formInfo = {
            id: this.state.id,
            language: this.state.language,
            image: this.state.image,
            expireDate: this.state.expireDate
        }

        if(this.props.updateNoticeId !== "") {
            console.log("after updateNoticeId handler", formInfo)
            ServicePopNotice.svcUpdatePopNotice(formInfo)
            .then(response => {
                console.log("notice updated !!!");
            })
            .catch(error => {
                window.alert("database error : "+error);
                console.log("database error "+ error)
            });
        }

    }
     

    componentDidMount() {
        console.log("PopNoticeDetails Toxel Error: " + this.props.updateNoticeId);
        if (this.props.updateNoticeId !== "") {
            ServicePopNotice.svcGetPopNoticeDetail(this.props.updateNoticeId)
                .then(response => {
                    console.log("response", response);
                    this.setState({
                        id: response.id,
                        image: response.image,
                        language: response.language,
                        expireDate: response.expireDate,

                    });
                })
                .catch(error => {
                    console.log("database error: " + JSON.stringify(error));
                    //window.alert("database error : "+error);
                });
        }
    }


    render() {
        return (
            <div className="col-md-8 order-md-1">
                <div>
                    <h4>회원 정보</h4>
                    <label htmlFor="id">ID</label>
                    <input type="text" className="form-control" id="id" value={this.state.id} onChange={this.handlerImageChange} readOnly></input>
                    <br />
                    <label htmlFor="id">Image</label>
                    <input type="text" className="form-control" id="image" value={this.state.image} onChange={this.handlerImageChange} ></input>
                    <br />
                    <label htmlFor="nickName">Language</label>
                    <input type="text" className="form-control" id="language" value={this.state.language} onChange={this.handlerLanguageChange} ></input>
                    <br />
                    <label htmlFor="nickName">ExpireDate</label>
                    <input type="text" className="form-control" id="expireDate" value={this.state.expireDate} onChange={this.updateExpireDate}></input>
                    <br />
                </div>
                <br />
                    <button className="btn btn-primary btn-lg btn-block" type="submit" onClick ={this.handlerFormSubmit}>Update Notice</button>
            </div>
        );

    }
}
export default PopNoticeDetailForm;