/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import * as ServiceNotification from "../service/ServiceNotifications";


class NotificationSend extends Component {
    constructor(props) {
        super(props);

        this.state = {
            notification: props.notification != null ? props.notification : {
                id: 'app',
                title: '',
                body: '',
                data: {
                    link: ''
                },
                android: {
                    channelId: 'custom_channel',
                    pressAction: {
                        id: 'default'
                    },
                    showTimestamp: true
                }
            },
            loading:false
        }
        this.innerConstructor();
    }

    innerConstructor() {
    }

    handlerFormSubmit = () => {
        this.setState({loading:true})
        console.log("notification", this.state.notification)
        const notification = this.state.notification.data.link ? { ...this.state.notification, android: { ...this.state.notification.android, pressAction: { id: 'link' } } } : this.state.notification

        ServiceNotification.svcNotifyByTopic(notification).then(() => {
            window.confirm("알림 보냈습니다!")
            window.location = '/admin/notifications'
        })
    }

    handlerOnTitleChange = (e) =>{
        const { value } = e.target;
        this.setState({ notification: { ...this.state.notification, title: value } })
    }

    handlerOnTextChange = (e) => {
        const { value } = e.target;
        this.setState({ notification: { ...this.state.notification, body: value } })
    }

    handlerOnImageChange = (e) => {
        const { value } = e.target;
        const android = { ...this.state.notification.android, style: { type: 0, picture: value } }
        if (!e.target.value.trim()) delete android.style
        this.setState({ notification: { ...this.state.notification, android } })
    }

    handlerOnLinkChange = (e) => {
        const { value } = e.target;
        this.setState({ notification: { ...this.state.notification, data: { link: value } } })
    }


    render() {
        return (
            <div className="mt-5">
                <label htmlFor="title">제목</label>
                <input type="text" className="form-control" placeholder="제목 입력..." id="title" value={this.state.notification.title} onChange={this.handlerOnTitleChange}></input>
                <br />
                <label htmlFor="text">텍스트</label>
                <textarea type="text" className="form-control" placeholder="텍스트 입력..." id="text" value={this.state.notification.body} onChange={this.handlerOnTextChange}></textarea>
                <br />
                <label htmlFor="image">이미지(선택사항)</label>
                <input type="text" placeholder="이미지 입력..." className="form-control" id="image" value={this.state.notification.android.style?.picture} onChange={this.handlerOnImageChange}></input>
                <br />
                <label htmlFor="link">링크(선택사항)</label>
                <input type="text" placeholder="링크 입력..." className="form-control" id="link" value={this.state.notification.data?.link} onChange={this.handlerOnLinkChange}></input>
                <br />
                {this.state.loading && <div className="row justify-content-center">
                    <div className="loader"></div>
                </div>}
                <br />
                <button className="btn btn-primary btn-block" onClick={this.handlerFormSubmit}>알림 보내기</button>
                <br />
            </div>
        );
    }
}

export default NotificationSend;