/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import * as ServiceNotification from "../service/ServiceNotifications";
import { shortenString } from "../utils/StringUtils";
import { formatDate } from "../utils/TimeUtil";

class NotificationList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notifs: null,
            startDates: [''],
            pageIndex: 0,
            filtered: false,
            filter: ''
        }
        this.handlerOnBtnDel = this.handlerOnBtnDel.bind(this);
        this.handlerOnPage = this.handlerOnPage.bind(this);
        this.handlerOnSearchChange = this.handlerOnSearchChange.bind(this);
        this.handlerOnSearch = this.handlerOnSearch.bind(this);
        this.innerConstructor();
    }

    handlerOnSearchChange(event) {
        this.setState({ filter: event.target.value });
        if (event.target.value.length == 0) this.innerConstructor()
    }

    handlerOnSearch() {
        ServiceNotification.svcSearchNotification(this.state.filter.trim()).then((response) => {
            this.setState({ notifs: response });
            this.setState({ filtered: true })
        }).catch((error) => {
            console.log("error", error)
        })
    }

    handlerOnBtnDel(id) {
        if (window.confirm("삭제하시겠습니까?")) {
            ServiceNotification.svcDelNotification(id)
                .then(response => {
                    console.log(response);
                    this.innerConstructor();
                })
                .catch(error => {
                    console.log("UserList.js : handlerOnBtnDel : fetch list error " + error);
                });
        }
    }

    handlerOnPage(add) {
        if (this.state.pageIndex + add < 0) return
        ServiceNotification.svcNotificationList(this.state.startDates[this.state.pageIndex + add], this.props.filter)
            .then(response => {
                if (!response || response.length == 0) return;
                this.setState({ pageIndex: this.state.pageIndex + add })
                if (add > 0) {
                    const lastDate = response[response.length - 1].timestamp
                    if (!this.state.startDates.includes(lastDate)) {
                        this.setState({ startDates: [...this.state.startDates, lastDate] })
                    }
                }
                this.setState({ notifs: response });
            })
            .catch(error => {
                console.log("UserList.js : innerConstructor : fetch list error " + error);
            });
    }

    innerConstructor() {
        ServiceNotification.svcNotificationList(null)
            .then(response => {
                console.log("Resp", response[response.length - 1].timestamp)
                const lastDate = response[response.length - 1].timestamp
                this.setState({ startDates: ['', lastDate] })
                this.setState({ notifs: response });
                this.setState({ filtered: false })
            })
            .catch(error => {
                console.log("UserList.js : innerConstructor : fetch list error " + error);
            });

    }

    render() {

        if (!this.state.notifs) {
            return <div />
        };

        return (
            <div className="container-fluid">

                <div className="row table-responsive">

                    <div className="input-group mb-5">
                        <input type="text" className="form-control" placeholder="제목 입력..." value={this.state.filter} onChange={this.handlerOnSearchChange} aria-label="Filter" aria-describedby="basic-addon2" />
                        <div className="input-group-append">
                            <button className="btn btn-outline-secondary" onClick={this.handlerOnSearch}>검색</button>
                        </div>
                    </div>

                    <table className="table table-striped table-sm">
                        <thead>
                            <tr>
                                <th>NO</th>
                                <th>제목</th>
                                <th>내용</th>
                                <th>전송일</th>
                                <th>보기</th>
                                <th>삭제</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.notifs.map((val, i) => {

                                return (
                                    <tr key={i}>
                                        <td>{i}</td>
                                        <td>{val.title}</td>
                                        <td>{shortenString(val.body)}</td>
                                        <td>{formatDate(val.timestamp, true)}</td>
                                        <td>
                                            <button className={"btn btn-sm btn-outline-primary"} onClick={() => { this.props.callbackForDetail(val) }}>보기</button>
                                        </td>
                                        <td>
                                            <button className={"btn btn-sm btn-outline-danger"} onClick={() => { this.handlerOnBtnDel(val.id) }}>삭제</button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                </div>
                <div className="row overflow-auto">
                    {!this.state.filtered &&
                        <nav aria-label="Page navigation example">
                            <ul className="pagination">
                                <li className="page-item"><a className="page-link bg-secondary text-white" onClick={() => { this.handlerOnPage(-1) }}>이전</a></li>
                                <li className="page-item"><a className="page-link" onClick={() => { this.handlerOnPage(1) }}>다음</a></li>
                            </ul>
                        </nav>
                    }
                </div>
            </div>
        );
    }
}

export default NotificationList;
