import { addDoc, collection, deleteDoc, doc, getDocs, limit, orderBy, query, startAfter, where } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { dateToTimestamp, timestampToDate } from "../utils/TimeUtil";
import { firestore, functions } from "./FirebaseInstance";

const notifRef = collection(firestore, 'notifications')

export function svcNotifyByTopic(notification, topic='app') {
    const notifyByTopic = httpsCallable(functions, 'notifyByTopic');
    svcAddNotification(notification)
    return new Promise((resolve, reject) =>
        notifyByTopic({notifee: notification, topic: topic}).then((response) => resolve(response.data)).catch(reject)
    )
}

export function svcNotifyByToken(notification, token) {
    const notifyByToken = httpsCallable(functions, 'notifyByToken');
    return new Promise((resolve, reject) =>
        notifyByToken({data: notification, registrationToken:token}).then((response) => resolve(response.data)).catch(reject)
    )
}

export function svcSearchNotification(filter) {
    return new Promise(function (resolve, reject) {
        const q = query(notifRef, where('title', "==", filter))
        getDocs(q).then((data) => {
            const notifs = data.docs.map((doc) => ({ ...doc.data(), timestamp: timestampToDate(doc.data().timestamp) }))
            resolve(notifs)
        }).catch((error) => {
            reject(error)
        })
    })
}


export function svcNotificationList(lastDate) {
    lastDate = lastDate ? dateToTimestamp(lastDate) : null

    return new Promise(function (resolve, reject) {
        const q = lastDate ? query(notifRef, orderBy('timestamp', 'desc'), startAfter(lastDate), limit(5)) : query(notifRef, orderBy('timestamp', 'desc'), limit(5))
        
        getDocs(q).then((data) => {
            const notifs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id, timestamp: timestampToDate(doc.data().timestamp) }))
            console.log("notifs", notifs[0])
            resolve(notifs)
        }).catch((error) => {
            reject(error)
        })
    });
}


export function svcDelNotification(id) {
    const notifRef = doc(firestore, 'notifications/' + id)
    return new Promise(function (resolve, reject) {
        deleteDoc(notifRef).then(() => {
            resolve()
        }).catch(reject)
    });
}

export function svcAddNotification(notification) {
    return new Promise(function (resolve, reject) {
        addDoc(notifRef, {...notification, timestamp: dateToTimestamp(new Date())}).then(() => {
            resolve()
        }).catch(reject)
    });
}
