import axios from "axios";

import backServer from "./ServerInfo";

export function svcSerialGeneration(formdata) {
    return new Promise(function(resolve,reject){
        axios.post(backServer + "/genserials",
        formdata,
        {headers:{"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            resolve(response.data);
        })
        .catch(error => {
            reject();
        })
    });

}
export function svcResetSerial(sirialNumber){
    return new Promise(function(resolve, reject){
       axios.post(backServer+"/resetserial",
        {
          sirialNumber:sirialNumber,
          DBId:1
        },
        {headers:{"Authorization":"Bearer "+sessionStorage.getItem("jKey")}}
       )
       .then(response => {
         if(response.data.status === "ok") {
           resolve(response.data)
         }else{
           reject()
         }
       })
       .catch(error => {
         reject()
       })
   })
}

export function svcSerialCount(filter) {
  console.log("svcSerialCount")
    return new Promise(function(resolve,reject){
        axios.post(backServer + "/getserialcount",
        {
          sirialNumber:filter,
          date: "",
          DBId:1
        },
        {headers:{"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
          console.log("Count: "+response.data)
            resolve(response.data);
        })
        .catch(error => {
            console.log(error);
        })
    })
}

export function svcSerialDateList() {
    return new Promise (function(resolve,reject){
        axios.post(backServer + "/serialdate",
        {DBId:1},
        {headers:{"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            if(response.data.status === "fail" || response.data.status === "Authfail") {
                console.log("comm not ok");
                reject();
            } else {
                resolve(response.data);
            }
        })
        .catch(error => {
            reject();
        })
    });
}

export function svcGetSerials(start, limit, filter) {
    return new Promise (function(resolve,reject){
        axios.post(backServer + "/getserials",
        {
          start:(start-1)*limit,
          limit:limit,
          sirialNumber:filter,
          date:"",
          DBId:1
        },
        {headers:{"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            resolve(response.data);
        })
        .catch(error => {
            reject();
        })
    });
}

export function testFunction(str1,str2) {
    console.log("ServiceSerial.js:testFucntion: this test fucntion");
    console.log(str1);
    console.log(str2);
}
