import axios from "axios";

import backServer from "./ServerInfo";

export function svcNoticeList() {
    return new Promise (function(resolve,reject){
        axios.post(backServer + "/listnotice",
        {DBId:1},
		{headers:{"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            if(response.data.status === "fail") {
                reject();
            } else {
                resolve(response.data);
            }
        })
        .catch(error => {
//            console.log("ServiceLogin.js : svcLoginCheckPost : error axios");
            window.location="/admin/login";
            reject();
        });
    });
}

export function svcAddNotice(value) {
    return new Promise (function(resolve,reject){
        axios.post(backServer + "/addnotice",
        value,
        {headers:{'Content-Type': 'application/json',"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            if(response.data.status === "fail") {
                reject();
            } else {
                resolve();
            }
        })
        .catch(error => {
            reject();
        });
    });
}

export function svcUpdateNotice(value) {
    return new Promise (function(resolve,reject){
        axios.post(backServer + "/updatenotice",
        value,
        {headers:{'Content-Type': 'application/json',"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            if(response.data.status === "fail") {
                reject();
            } else {
                resolve();
            }
        })
        .catch(error => {
            reject();
        });
    });
}

export function svcDelNotice(value) {
    return new Promise (function(resolve,reject){
        var senddata = {
            id:value,
            DBId:1
        }
        axios.post(backServer + "/delnotice",
        senddata,
        {headers:{'Content-Type': 'application/json',"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            if(response.data.status === "fail") {
                reject();
            } else {
                resolve();
            }
        })
        .catch(error => {
            reject();
        });
    });
}

export function svcGetNotice(value) {
    return new Promise (function(resolve,reject){
        var senddata = {
            id:value,
            DBId:1
        }
        axios.post(backServer + "/getnotice",
        senddata,
        {headers:{'Content-Type': 'application/json',"Authorization":"Bearer "+sessionStorage.getItem("jKey")}})
        .then(response => {
            if(response.data.status === "fail") {
                reject();
            } else {
                resolve(response.data);
            }
        })
        .catch(error => {
            reject();
        });
    });
}
