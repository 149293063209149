import React, {Component} from "react";
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import Footer from "../components/Footer";
import NotificationSend from "../components/NotificationSend";
import NotificationList from "../components/NotificationList";

class NotificationManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewMode: 'list',
            notification: null
        }
        this.innerConstructor()
    }

    handlerListNotifs = () => {
        this.setState({ viewMode: 'list', notification: null })
    }

    handlerDetailNotif = (notif) => {
        this.setState({ viewMode: 'detail', notification: notif })
    }
    handlerSendNotif = () => {
        this.setState({ viewMode: 'detail', notification: null })
    }

    innerConstructor() {
        //check if login later if necessary
    }

    render() {
        return (
            <div>
                <NavBar />
                <div className="container-fluid">
                    <div className="row">
                        <SideBar />
                        <main className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">

                            {this.state.viewMode === 'list' &&
                                <div>
                                    <h2>푸시 알림 관리</h2>
                                    <div className="col-md-12  text-right mb-3">
                                        <button className="btn btn-outline-primary btn-sm" onClick={this.handlerSendNotif}>알림 전송</button>
                                        <br />
                                    </div>
                                    <NotificationList callbackForDetail={this.handlerDetailNotif} />
                                </div>}
                            {this.state.viewMode === 'detail' &&
                                <div>
                                    <h2>푸시 알림 전송</h2>
                                    <div className="col-md-12  text-right mb-3">
                                        <button className="btn btn-outline-primary btn-sm" onClick={this.handlerListNotifs}>알림 내역</button>
                                        <br />
                                    </div>
                                    <NotificationSend notification={this.state.notification} />
                                </div>}

                        </main>
                    </div>
                    <Footer />
                </div >
            </div >
        );
    }
}


export default NotificationManagement;
