import React, {Component} from "react";
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import Footer from "../components/Footer";
import NoticeList from "../components/NoticeList";
import NoticeView from "../components/NoticeView";
import NoticeAddForm from "../components/NoticeAddForm";
import * as ServiceLogin from "../service/ServiceLogin";

class Notices extends Component {
    constructor(props) {
        super(props);

        this.state = {
            viewMode : "list",
            NoticeId : "",
            updateUserId : ""
        }

        this.handlerAddNotice = this.handlerAddNotice.bind(this);
        this.handlerListNotice = this.handlerListNotice.bind(this);
        this.handlerUpdateNotice = this.handlerUpdateNotice.bind(this);
        this.handlerDetailUser = this.handlerDetailUser.bind(this);
        this.handlerViewNotice = this.handlerViewNotice.bind(this);

        this.innerConstructor();
    }

    preRender() {
        console.log("DashBoard.js : preRender : rendering");
    }

    innerConstructor() {
        ServiceLogin.svcLoginCheck();
        console.log("UserManagement.js : innerConstructor : end");
    }

    handlerAddNotice() {
        this.setState({viewMode:"add",NoticeId:""});
    }

    handlerListNotice() {
        this.setState({viewMode:"list"});
    }

    handlerUpdateNotice(id) {
        this.setState({viewMode:"add",NoticeId:id});
    }

    handlerViewNotice(id) {
        this.setState({viewMode:"view",NoticeId:id});
    }

    handlerDetailUser(id) {
        this.setState({viewMode:"detail",updateUserId:id});
    }

    render() {
        this.preRender();
        return (
            <div>
                <NavBar />
                <div className="container-fluid">
                    <div className="row">
                        <SideBar />
                        <main className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
                            { this.state.viewMode === "list" &&
                                <div>
                                    <h2>Notice Board</h2>
                                    <div className="col-md-12 text-right mb-3">
                                        <button className="btn btn-outline-primary btn-sm" onClick={this.handlerAddNotice}>Add New Notice</button><br />
                                    </div>
                                    <div>
                                        <NoticeList callBackForView={this.handlerViewNotice} callBackForUpdate={this.handlerUpdateNotice} callBackForDetail={this.handlerDetailUser}/>
                                    </div>
                                </div>
                            }
                            {
                                this.state.viewMode === "add" &&
                                <div>
                                    <h2>Add Notice</h2>
                                    <div className="col-md-12 text-right mb-3">
                                        <button className="btn btn-outline-primary btn-sm" onClick={this.handlerListNotice}>Go To List</button><br />
                                    </div>
                                    <div>
                                        <NoticeAddForm NoticeId={this.state.NoticeId}/>
                                    </div>
                                </div>
                            }
                            {
                                this.state.viewMode === "view" &&
                                <div>
                                    <h2>Detail User info</h2>
                                    <div className="col-md-12 text-right mb-3">
                                        <button className="btn btn-outline-primary btn-sm" onClick={this.handlerListNotice}>Go To List</button><br />
                                    </div>
                                    <div>
                                        <NoticeView NoticeId={this.state.NoticeId}/>
                                    </div>
                                </div>
                            }
                        </main>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default Notices;