import React, {Component} from "react";
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import Footer from "../components/Footer";
import * as ServiceLogin from "../service/ServiceLogin";
import * as ServiceUser from "../service/ServiceUser";
import * as TimeUtil from "../utils/TimeUtil";
import Chart from "react-google-charts";
const pointStyle = 'point { size: 3.5; shape-type: circle; fill-color: #ffffff; stroke-color: #37508c; stroke-width:1.5; }';
var date_array = [['Date', 'Game Count', {'type': 'string', 'role': 'style'}]]
var gameId_array = [['GameID', 'Game Count', {'type': 'string', 'role': 'style'}]]
var gameNames = ["구름을 잡아라!", "에어 쇼","에덴월드","기억력 계산기", "두더지 잡기","연못 대작전","오투스 학교"];
var tdyGameCount = 0;
var chartStyle = "chart { stroke-width:3;}";

class DashBoard extends Component {

  constructor(props) {
      super(props);

      this.state = {
          usersCount :0,
          tdyGameCount:0
      }


      this.innerConstructor();
  }


  datesDataArray(data){

    if(!data) return date_array;
    var prev = "";
    var dateMap = new Map();
    var gameIdMap = new Map();
   for(var i=0; i<data.length; i++){
       if(dateMap.has(data[i].date))
         dateMap.set(data[i].date, dateMap.get(data[i].date)+1)
       else {
          dateMap.set(data[i].date, 1)
       }
       if(gameIdMap.has(data[i].gameId))
         gameIdMap.set(data[i].gameId, gameIdMap.get(data[i].gameId)+1)
       else {
          gameIdMap.set(data[i].gameId, 1)
       }

    }

    this.setState({tdyGameCount:dateMap.get(TimeUtil.formatDate(new Date(), true, "-"))});
    console.log("Today day: "+tdyGameCount+" "+TimeUtil.formatDate(new Date(), true, "-"))
    dateMap.forEach(this.mapTodatesDataArray)
    gameIdMap.forEach(this.mapToGameIdDataArray)
  }

  mapToGameIdDataArray(value, key, map){
    gameId_array.push([gameNames[parseInt(key)-1], value, pointStyle+','+chartStyle])
  }

  mapTodatesDataArray(value, key, map){
    date_array.push([key.substring(5, 10), value, pointStyle+','+chartStyle])
  }

  innerConstructor() {
      ServiceLogin.svcLoginCheck();
      console.log("UserManagement.js : innerConstructor : end");
  }
  componentDidMount(){
    var dateMin = new Date();
    dateMin.setDate(dateMin.getDate() - 30);
    var dateMax = new Date();
      ServiceUser.svcGetUsersGameData(TimeUtil.formatDate(dateMin, true, "-"), TimeUtil.formatDate(dateMax, true, "-"), 0, 0)
      .then(response => {
        this.datesDataArray(response)
      });
      ServiceUser.svcUserCount("")
      .then(response => {
          this.setState({usersCount:response.count});
      })
      .catch(error  => {
        console.log("Couldn't fetch data: "+error)
      });

  }

    preRender() {
        console.log("DashBoard.js : preRender : rendering");
    }

    render() {
        this.preRender();
        return (
            <div>
                <NavBar />
                <div className="container-fluid">
                    <div className="row">
                        <SideBar />
                        <main className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
                        <br/>
                        <h1>{TimeUtil.formatDate(new Date(), true, "/")}</h1>
                        <br/>
                        <table className="table table-striped table-sm">
                            <thead>
                                <tr>
                                    <th>오투스+ 앱 버전</th>
                                    <th>회원 횟수 </th>
                                    <th>오늘 게임 횟수</th>
                                </tr>
                            </thead>
                            <tbody>

                                    <tr>
                                      <td>3.0.3</td>
                                      <td>{this.state.usersCount}</td>
                                      <td>{this.state.tdyGameCount}</td>
                                    </tr>

                            </tbody>
                        </table>
                        {
                        <Chart
                              height={235}
                              chartType="LineChart"
                              loader={<div>Loading Chart</div>}
                              data={date_array}


                              options={{
                                  title:"Last 30 days Game Count",
                                  pointShape: {type: 'circle'},
                                  pointSize: 5,
                                  vAxis: {
                                      textStyle:{
                                          color:"#6E6E6E",
                                          fontName:"NanumSquare",
                                          fontSize:10
                                      },
                                      baselineColor:"#6E6E3E"
                                  },
                                  hAxis: {
                                      textStyle:{
                                          color:"#6E6E6E",
                                          fontName:"NanumSquare",
                                          fontSize:7,
                                      },
                                      maxAlternation: 1,
                                      showTextEvery: 1,
                                      minTextSpacing: 8,
                                  },


                              }
                          }/>}
                          <br/><br/>
                          <Chart

                                chartType="ColumnChart"
                                loader={<div>Loading Chart</div>}
                                data={gameId_array}
                                height={235}

                                options={{
                                    title:"Played games in last 30 days",
                                    pointShape: {type: 'circle'},
                                    pointSize: 5,
                                    vAxis: {
                                        textStyle:{
                                            color:"#6E6E6E",
                                            fontName:"NanumSquare",
                                            fontSize:10
                                        },
                                        baselineColor:"#6E6E3E"
                                    },
                                    hAxis: {
                                        textStyle:{
                                            color:"#6E6E6E",
                                            fontName:"NanumSquare",
                                            fontSize:10,
                                        },
                                        maxAlternation: 1,
                                        showTextEvery: 1,
                                        minTextSpacing: 8,
                                    }


                                }
                            }/>

                        </main>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default DashBoard;
