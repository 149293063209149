import React, { Component } from "react";
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import Footer from "../components/Footer";
import PopNoticesList from "../components/PopNoticeList";
import UserAddForm from "../components/UserAddForm";
import * as ServiceLogin from "../service/ServiceLogin";
import PopNoticeDetailForm from "../components/PopNoticeDetailForm";
import PopNoticeAddForm from "../components/PopNoticeAddForm";

class PopNoticeManagement extends Component{
    constructor(props) {
        super(props);

        this.state = {
            viewMode : "list",
            noticeToUpdate : "",
            filter: "",
            filtered:1
        }
        // this.handlerAddSerial = this.handlerAddSerial.bind(this);
        this.handlerAddNotice = this.handlerAddNotice.bind(this);
        this.handlerListNotice = this.handlerListNotice.bind(this);
        this.handlerDetailNotice = this.handlerDetailNotice.bind(this);
        this.handlerOnChange = this.handlerOnChange.bind(this);
        this.handlerOnSearch = this.handlerOnSearch.bind(this);
        this.handlerListPopNotice = this.handlerListPopNotice.bind(this);

        this.innerConstructor();
    }
    innerConstructor() {
        ServiceLogin.svcLoginCheck();
        console.log("NoticeManagement.js : innerConstructor : end");
    }
    // handlerAddSerial() {
    //     //new
    //       this.setState({viewMode:"add",SirialNumber:""});
    //       console.log("handler add user");
    //   }

    handlerAddNotice() {
        this.setState({viewMode:"add"});
        console.log("handler add Notice");
    }


    handlerListNotice() {
        this.setState({viewMode:"list"});
        this.setState({filter:''})
    }


    handlerOnBtnDetail(id) {
        console.log("UID: "+id);
        this.props.callBackForDetail(id);
    }


    handlerDetailNotice(id) {
           console.log("UID: Toxel "+id);
        this.setState({viewMode:"detail", noticeToUpdate:id});
    }
    handlerOnChange(event){
      this.setState({filter:event.target.value});
    }
    handlerOnSearch(event){
      event.preventDefault();
      this.setState({filtered: this.state.filtered+1})
    }
    handlerListPopNotice() {
        this.setState({viewMode:"list"});
        this.setState({filter:''})
    }

    render() {
       
        return (
            <div>
                <NavBar />
                <div className="container-fluid">
                    <div className="row">
                        <SideBar />
                        <main className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
                            { this.state.viewMode === "list" &&
                                <div>
                                    <h2>Notice 관리</h2>
                                    <form onSubmit={this.handlerOnSearch}>
                                        <div className="input-group mb-3">
                                          <input type="text" className="form-control" placeholder="아이디, 전화번호, 시리얼번호 또는 닉네임 입력..." value={this.state.filter} onChange={this.handlerOnChange} aria-label="Filter" aria-describedby="basic-addon2"/>
                                             <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" type="submit">검색</button>
                                             </div>
                                        </div>
                                    </form>
                                    <div className="col-md-12 text-right mb-3">
                                        <button className="btn btn-outline-primary btn-sm" onClick={this.handlerAddNotice}>Create Notice</button><br />
                                    </div>
                                    <div key={this.state.filtered}>
                                        <PopNoticesList callBackForUpdate={this.handlerUpdateNotice} callBackForDetail={this.handlerDetailNotice} filter={this.state.filter}/>
                                    </div>
                                </div>
                            }
                            {
                                this.state.viewMode === "add" &&
                                <div>
                                    <h2>Add Notice Form</h2>
                                    <div className="col-md-12 text-right mb-3">
                                        <button className="btn btn-outline-primary btn-sm" onClick={this.handlerListPopNotice}>회원 내역</button><br />
                                    </div>
                                    <div>
                                        <PopNoticeAddForm viewMode={this.state.viewMode}/>
                                    </div>
                                </div>
                            }
                            {
                                this.state.viewMode === "detail" &&
                                <div>
                                    <h2>Notice Details</h2>
                                    <div className="col-md-12 text-right mb-3">
                                        <button className="btn btn-outline-primary btn-sm" onClick={this.handlerListPopNotice}>회원 내역</button><br />
                                    </div>
                                    <div>
                                        <PopNoticeAddForm data={this.state.noticeToUpdate} viewMode={this.state.viewMode}/>
                                    </div>
                                </div>
                            }
                        </main>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default PopNoticeManagement;


